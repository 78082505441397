import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGetClientSavedSearchesQuery } from '@bvi/admin-panel/entities/clients/api-slice';
import { Span } from '@bvi/common-components';

import { styles } from '../client-cases-tab/styles';

import { ClientSavedSearchesTable } from './client-saved-searches-table';

export interface IClientSavedSearchesProperties {
  companyId: number;
}

export const ClientSavedSearchesTab: React.FC<
  IClientSavedSearchesProperties
> = ({ companyId }) => {
  const { t } = useTranslation();

  const { isFetching, data } = useGetClientSavedSearchesQuery(companyId);

  const savedSearches = data?.success ? data.payload.data : [];

  return (
    <Stack>
      <Typography fontWeight="bold" pb={3}>
        <Span>{t('clients.details.tabs.savedSearches.title')}</Span>
        {!isFetching && (
          <Span sx={styles.clientCasesCount}>{savedSearches.length}</Span>
        )}
      </Typography>
      <ClientSavedSearchesTable
        isLoading={isFetching}
        savedSearches={savedSearches}
      />
    </Stack>
  );
};
