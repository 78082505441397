import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { apiContainer } from '@bvi/admin-panel/shared/api/base-rtk-api';

export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'],
) => {
  const { reducerPath, reducer, middleware } = apiContainer;
  return configureStore({
    reducer: {
      [reducerPath]: reducer,
    },
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      middleware,
    ],
    enhancers: [Sentry.createReduxEnhancer()],
    ...options,
  });
};
