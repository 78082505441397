export const styles = {
  statusText: {
    display: 'inline-block',
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  declineReasonIcon: {
    pl: '10px',
  },
};
