import { Container, Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { Header } from '@bvi/admin-panel/shared/api/header';

export const AdminPanelLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack minHeight="100vh">
      <Header />
      <Container maxWidth="xl">{children}</Container>
    </Stack>
  );
};
