import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-users-tab/styles';
import { ICompanyWithUsersData } from '@bvi/api-interfaces/response/company';
import { Span } from '@bvi/common-components';

import { ClientUsersTable } from './client-users-table';

export interface IClientUsersTabProperties {
  company: ICompanyWithUsersData;
}

export const ClientUsersTab: FC<IClientUsersTabProperties> = ({ company }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography fontWeight="bold" pb={3}>
        <Span>{t('clients.details.tabs.users.title')}</Span>
        <Span sx={styles.usersCount}>{company.users.length}</Span>
      </Typography>
      <ClientUsersTable company={company} />
    </>
  );
};
