import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { parse } from 'qs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ClientSubscriptionTab } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-subscription-tab';
import { ClientUsersTab } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-users-tab';
import {
  ClientDetailsTab,
  IClientTabBarProperties,
} from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/types';
import { styles } from '@bvi/admin-panel/pages/client-details-page/styles';
import { useNavigation } from '@bvi/navigation-utils';

import { ClientCasesTab } from './client-cases-tab';
import { ClientSavedSearchesTab } from './client-saved-searches-tab';

interface IClientTabQueryParameters {
  activeTab?: ClientDetailsTab;
}

export const ClientTabBar: FC<IClientTabBarProperties> = ({
  client,
  ...properties
}) => {
  const navigate = useNavigation();

  const location = useLocation();

  const searchParameters = parse(location.search, {
    ignoreQueryPrefix: true,
  }) as IClientTabQueryParameters;

  const { t } = useTranslation();

  const handleChange = (
    event: React.SyntheticEvent,
    activeTab: ClientDetailsTab,
  ) => {
    navigate(location.pathname, { activeTab });
  };

  return (
    <Box {...properties}>
      <TabContext value={searchParameters.activeTab ?? ClientDetailsTab.USERS}>
        <Box sx={styles.tabContainer}>
          <TabList onChange={handleChange}>
            <Tab
              label={t('clients.details.tabs.users.title')}
              value={ClientDetailsTab.USERS}
            />
            <Tab
              label={t('clients.details.tabs.cases.title')}
              value={ClientDetailsTab.CASES}
            />
            <Tab
              label={t('clients.details.tabs.savedSearches.title')}
              value={ClientDetailsTab.SAVED_SEARCHES}
            />
            <Tab
              label={t('clients.details.tabs.subscription.title')}
              value={ClientDetailsTab.SUBSCRIPTION}
            />
          </TabList>
        </Box>
        <TabPanel value={ClientDetailsTab.USERS} sx={styles.tabPanel}>
          <ClientUsersTab company={client.company} />
        </TabPanel>
        <TabPanel value={ClientDetailsTab.CASES} sx={styles.tabPanel}>
          <ClientCasesTab companyId={client.company.id} />
        </TabPanel>
        <TabPanel value={ClientDetailsTab.SAVED_SEARCHES} sx={styles.tabPanel}>
          <ClientSavedSearchesTab companyId={client.company.id} />
        </TabPanel>
        <TabPanel value={ClientDetailsTab.SUBSCRIPTION} sx={styles.tabPanel}>
          <ClientSubscriptionTab subscription={client.subscription} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
