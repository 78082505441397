import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { useRemoveCaseMutation } from '@bvi/admin-panel/entities/cases/api-slice';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { isServerError } from '@bvi/axios-query';
import { ConfirmActionMenuItem } from '@bvi/common-components';
import { useNavigation } from '@bvi/navigation-utils';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

export interface IManageCaseMenuProperties {
  case: ICaseData;
}

export const ManageCaseMenu: FC<IManageCaseMenuProperties> = ({
  case: _case,
}) => {
  const { t } = useTranslation();
  const { showNotificationMessage } = useNotistack();
  const navigate = useNavigation();

  const [removeCase, { isLoading: isRemoveCasePending, error }] =
    useRemoveCaseMutation();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClickOnMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const onRemoveCaseClick = async () => {
    await removeCase(_case.id);
  };

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
      );
    }
  }, [error, showNotificationMessage]);

  return (
    <>
      <IconButton onClick={handleClickOnMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() =>
            navigate(
              generatePath(AdminPanelRoutesPaths.CASES_EDIT, {
                id: String(_case.id),
              }),
            )
          }
        >
          {t('cases.list.actions.edit.caption')}
        </MenuItem>
        <ConfirmActionMenuItem
          question={t('cases.list.actions.remove.question')}
          confirm={t('dialog.confirm')}
          cancel={t('dialog.cancel')}
          onClick={onRemoveCaseClick}
          componentProperties={{ disabled: isRemoveCasePending }}
        >
          {t('cases.list.actions.remove.caption')}
        </ConfirmActionMenuItem>
      </Menu>
    </>
  );
};
