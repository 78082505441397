import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IManageUserMenuItemProperties } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu/types';
import { UserStatus } from '@bvi/api-interfaces/entity/user';
import { ConfirmActionMenuItem } from '@bvi/common-components';

export const BlockUserMenuItem: FC<IManageUserMenuItemProperties> = ({
  userStatus,
  onClick,
}) => {
  const { t } = useTranslation();

  return userStatus === UserStatus.WAITING_CONFIRMATION ||
    userStatus === UserStatus.CONFIRMED ? (
    <ConfirmActionMenuItem
      question={t('users.actions.block.question')}
      confirm={t('dialog.confirm')}
      cancel={t('dialog.cancel')}
      onClick={onClick}
    >
      <Typography color="error">{t('users.actions.block.caption')}</Typography>
    </ConfirmActionMenuItem>
  ) : null;
};
