import { TFunction } from 'i18next';
import { z } from 'zod';

export const buildValidationSchema = (t: TFunction) => {
  const validationFields = {
    name: z.string().min(1, t('error.form.required')).default(''),
    contactPerson: z.string().min(1, t('error.form.required')).default(''),
    contactEmail: z
      .string()
      .min(1, t('error.form.required'))
      .email(t('error.form.email'))
      .default(''),
    companySizeId: z.number({
      invalid_type_error: t('error.form.required'),
      required_error: t('error.form.required'),
    }),
    subscriptionPlanId: z.number({
      invalid_type_error: t('error.form.required'),
      required_error: t('error.form.required'),
    }),
    subscriptionExpiresAt: z
      .number({
        invalid_type_error: t('error.form.required'),
      })
      .optional(),
  };

  return z.object(validationFields);
};
