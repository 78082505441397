import { useLocalStorage } from 'usehooks-ts';

import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import {
  ICreateCaseRequest,
  IUpdateCaseRequest,
} from '@bvi/api-interfaces/request/case';
import { mapCaseFormDataToSearch } from '@bvi/cases-form';
import { SEARCH_PARAMS_KEY } from '@bvi/cases-search';
import { useNavigation } from '@bvi/navigation-utils';

export const useNavigateAfterSubmit = () => {
  const navigate = useNavigation();
  const [savedSearchParameters] = useLocalStorage(SEARCH_PARAMS_KEY, '{}');

  return (
    caseId: number,
    data: ICreateCaseRequest | IUpdateCaseRequest,
    shouldSearch?: boolean,
  ) => {
    if (shouldSearch) {
      const mappedData = mapCaseFormDataToSearch(data);
      navigate(AdminPanelRoutesPaths.CASES_LIST, mappedData);
    } else {
      const parsedSearchParameters = JSON.parse(savedSearchParameters);

      navigate(AdminPanelRoutesPaths.CASES_LIST, parsedSearchParameters);
    }
  };
};
