import { createSelector } from '@reduxjs/toolkit';

import { searchApi } from '@bvi/admin-panel/entities/search/api-slice';
import { BaseResponse } from '@bvi/api-interfaces/response/base';
import {
  ICaseStatisticsData,
  IFullCaseListResponse,
} from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { IGetSettingsQuery } from '@bvi/cases-search';

export const selectSearchCasesResult = (parameters: Record<string, unknown>) =>
  searchApi.endpoints.getCases.select(parameters);

export const selectSettings = (data?: BaseResponse<ISettingsData>) => {
  if (!data?.success) {
    return {} as ISettingsData;
  }

  return data.payload.data;
};

export const selectCases = (data?: IFullCaseListResponse) => {
  if (!data?.success) {
    return [];
  }

  return data.payload.data;
};

export const selectStatistic = (data?: BaseResponse<ICaseStatisticsData>) => {
  if (!data?.success) {
    return {} as ICaseStatisticsData;
  }

  return data.payload.data;
};

export const selectCasesTotalCount = (data?: IFullCaseListResponse) => {
  if (!data?.success) {
    return 0;
  }

  return data.payload.meta.total ?? 0;
};

export const selectCasesStatisticsResult = (
  parameters: Record<string, unknown>,
) => searchApi.endpoints.getStatistics.select(parameters);

export const selectStatisticsData = (parameters: Record<string, unknown>) =>
  createSelector(selectCasesStatisticsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ICaseStatisticsData;
    }

    return data.payload.data;
  });

export const selectCasesSettingsResult = (parameters: IGetSettingsQuery) =>
  searchApi.endpoints.getSettings.select(parameters);
export const selectSettingsData = (parameters: IGetSettingsQuery) =>
  createSelector(selectCasesSettingsResult(parameters), ({ data }) => {
    if (!data?.success) {
      return {} as ISettingsData;
    }

    return data.payload.data;
  });
