import { routes } from '@bvi/admin-panel/api-routes';
import {
  apiContainer,
  getListTag,
  invalidateTags,
  TagTypes,
  WithId,
} from '@bvi/admin-panel/shared/api';
import { IUpdateUserRequest } from '@bvi/api-interfaces/request/user';
import { IUserResponse } from '@bvi/api-interfaces/response/user';

const usersApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation<IUserResponse, WithId<IUpdateUserRequest>>({
      query: ([id, data]) => ({
        url: routes.adminUsers.updateById(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.CLIENTS)),
    }),
  }),
});

export const { useUpdateUserMutation } = usersApi;
