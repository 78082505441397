import { TFunction } from 'i18next';
import { z } from 'zod';

export const buildValidationSchema = (t: TFunction) => {
  const validationFields = {
    subscriptionPlanId: z.number({
      invalid_type_error: t('error.form.required'),
      required_error: t('error.form.required'),
    }),
    subscriptionExpiresAt: z.number().or(z.date()).nullable(),
  };

  return z.object(validationFields);
};
