import { BoxProps } from '@mui/material';

import { IClientWithCompanyData } from '@bvi/api-interfaces/response/client';

export interface IClientTabBarProperties extends BoxProps {
  client: IClientWithCompanyData;
}

export enum ClientDetailsTab {
  USERS = 'USERS',
  CASES = 'CASES',
  SAVED_SEARCHES = 'SAVED_SEARCHES',
  SUBSCRIPTION = 'SUBSCRIPTION',
}
