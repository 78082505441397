import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { parse } from 'qs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useLazyGetStatisticsQuery } from '@bvi/admin-panel/entities/search/api-slice';
import {
  selectSettingsData,
  selectStatistic,
} from '@bvi/admin-panel/entities/search/selectors';
import { IAdminSearchFilterFormData } from '@bvi/admin-panel/feature/search/lib/types';
import { isServerError } from '@bvi/axios-query';
import {
  ResultsChart,
  ResultsTable,
  searchFormPropertySchemas,
  SearchTags,
  StatisticsAlert,
  buildParametersForSettings,
  buildAgeCategoryQuery,
} from '@bvi/cases-search';

import { useSearchNotification } from '../../lib/useSearchNotification';

export const SearchResult: React.FC = () => {
  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof IAdminSearchFilterFormData, string>;

  const searchParametersWithoutPagination = omit(searchParameters, [
    'page',
    'limit',
  ]);
  const statisticsSearchParameters = pick(
    searchParametersWithoutPagination,
    Object.keys(searchFormPropertySchemas),
  );

  const [
    getStatistics,
    { data: statisticsData, isFetching: isFetchingStatistics },
  ] = useLazyGetStatisticsQuery();
  const { showSearchNotificationMessage } = useSearchNotification();

  const handleLoadStatistics = async () => {
    try {
      const ageCategoryQuery = buildAgeCategoryQuery(searchParameters);
      const updatedSearchParameters = {
        ...statisticsSearchParameters,
        ...ageCategoryQuery,
      };

      await getStatistics(updatedSearchParameters, false).unwrap();
    } catch (error) {
      if (isServerError(error)) {
        showSearchNotificationMessage(error);
      }
    }
  };

  useEffect(() => {
    handleLoadStatistics();
  }, [location.search]);

  const statistics = selectStatistic(statisticsData);
  const settings = useSelector(
    selectSettingsData(
      buildParametersForSettings(searchParametersWithoutPagination),
    ),
  );

  return (
    <>
      <SearchTags data={settings} isLoading={isFetchingStatistics} />
      <StatisticsAlert data={statistics} isLoading={isFetchingStatistics} />
      <ResultsTable
        data={statistics}
        isLoading={isFetchingStatistics}
        tagData={settings}
        showGeometricMean
        shouldSticky
      />
      <ResultsChart data={statistics} isLoading={isFetchingStatistics} />
    </>
  );
};
