import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import times from 'lodash/times';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from '@bvi/admin-panel/feature/clients/ui/clients-table/styles';
import { IClientsTableProperties } from '@bvi/admin-panel/feature/clients/ui/clients-table/types';
import { Div, OverflowingText, TableRowSkeleton } from '@bvi/common-components';

export const ClientsTable: FC<IClientsTableProperties> = ({
  clients,
  loading,
  onClick,
  ...properties
}) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: t('clients.list.table.columns.clientName'),
      width: '24%',
    },
    {
      title: t('clients.list.table.columns.contactPerson'),
      width: '20%',
    },
    {
      title: t('clients.list.table.columns.contactEmail'),
      width: '20%',
    },
    {
      title: t('clients.list.table.columns.status'),
      width: '12%',
    },
    {
      title: t('clients.list.table.columns.numberOfCases'),
      width: '12%',
    },
    {
      title: t('clients.list.table.columns.numberOfUsers'),
      width: '12%',
    },
  ];

  return (
    <Div {...properties}>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {tableColumns.map((column, index) => (
                <TableCell width={column.width} key={index}>
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? times(2, (index) => (
                  <TableRowSkeleton columns={6} key={index} />
                ))
              : clients.map((client) => (
                  <TableRow
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={(event) => onClick?.(event, client.id)}
                    key={client.id}
                    aria-label={`go-to-client-${client.id}-details`}
                  >
                    <TableCell>
                      <OverflowingText
                        text={client.company.name}
                        sx={styles.companyName}
                      />
                    </TableCell>
                    <TableCell>
                      <OverflowingText text={client.company.contactPerson} />
                    </TableCell>
                    <TableCell>
                      <OverflowingText text={client.company.contactEmail} />
                    </TableCell>
                    <TableCell>{client.status}</TableCell>
                    <TableCell sx={styles.notAvailableColumn}>
                      {t('captions.notAvailable')}
                    </TableCell>
                    <TableCell>{client.company.users.length}</TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Div>
  );
};
