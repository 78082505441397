import { CircularProgress, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useGetCaseQuery } from '@bvi/admin-panel/entities/cases/api-slice';
import { selectCase } from '@bvi/admin-panel/entities/cases/selectors';
import { CasesEditForm } from '@bvi/admin-panel/feature/cases-form/ui/edit-form';

export const CasesEditPage = () => {
  const { id } = useParams();
  const caseId = Number(id);
  const { isLoading } = useGetCaseQuery({ id: caseId });

  const _case = useSelector(selectCase({ id: caseId }));

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack direction="column">
      <CasesEditForm case={_case} />
    </Stack>
  );
};
