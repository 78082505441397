import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import times from 'lodash/times';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ISavedSearchData } from '@bvi/api-interfaces/response/case';
import { TableRowSkeleton } from '@bvi/common-components';

import { SavedSearchRow } from './saved-search-row';
import { styles } from './styles';

export interface ISavedSearchesTableProperties {
  savedSearches: Array<ISavedSearchData>;
  isLoading?: boolean;
}

export const ClientSavedSearchesTable: FC<ISavedSearchesTableProperties> = ({
  savedSearches,
  isLoading,
}) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: t('clients.details.tabs.savedSearches.table.columns.searchDates'),
      width: '250px',
    },
    {
      title: t('clients.details.tabs.savedSearches.table.columns.userName'),
      width: 'auto',
    },
    {
      title: '',
      width: 'auto',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={styles.fixedTable}>
        <TableHead>
          <TableRow>
            {tableColumns.map((column, index) => (
              <TableCell key={index} width={column.width}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading
            ? times(2, (index) => <TableRowSkeleton columns={4} key={index} />)
            : savedSearches.map((savedSearch) => (
                <SavedSearchRow
                  key={`saved-search-${savedSearch.id}`}
                  savedSearch={savedSearch}
                />
              ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
