import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetClientsQuery } from '@bvi/admin-panel/entities/clients/api-slice';
import { selectClients } from '@bvi/admin-panel/entities/clients/selectors';
import { useUpdateUserMutation } from '@bvi/admin-panel/entities/users/api-slice';
import { IChangeUserCompanyFormProperties } from '@bvi/admin-panel/feature/clients/ui/change-company-form/types';
import { IUpdateUserRequest } from '@bvi/api-interfaces/request/user';
import { Form, Span } from '@bvi/common-components';
import { theme } from '@bvi/mui-theme';

export const ChangeUserCompanyForm: FC<IChangeUserCompanyFormProperties> = ({
  user,
  companyId,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const { data } = useGetClientsQuery(undefined, {
    selectFromResult: ({ data }) => ({ data: selectClients(data) }),
  });
  const companies = useMemo(() => data.map((client) => client.company), [data]);

  const methods = useForm<IUpdateUserRequest>();
  const { register, handleSubmit } = methods;

  const onSubmit = async (data: IUpdateUserRequest) => {
    await updateUser([user.id, data]);
    onSuccess?.();
  };

  return (
    <FormProvider {...methods}>
      <Typography variant="h1" mb={2}>
        <Span>{t('users.actions.move.titlePrefix')}&nbsp;</Span>
        <Span sx={{ color: theme.palette.primary.main }}>
          {user.profile.firstName} {user.profile.lastName}
        </Span>
        <Span>&nbsp;{t('users.actions.move.titlePostfix')}</Span>
      </Typography>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-company">Select Company</InputLabel>
              <Select
                {...register('companyId', {
                  valueAsNumber: true,
                  required: true,
                })}
                defaultValue={companyId}
              >
                {companies.map((company) => (
                  <MenuItem value={company.id} key={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
              autoFocus
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};
