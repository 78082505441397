import { zodResolver } from '@hookform/resolvers/zod';
import { Typography, Button, TextField } from '@mui/material';
import msx from 'mui-sx';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLoginMutation } from '@bvi/admin-panel/entities/admin-auth/api-slice';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { isServerError } from '@bvi/axios-query';
import { Div, Form, PasswordTextField } from '@bvi/common-components';
import { useNavigation } from '@bvi/navigation-utils';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';
import { styles } from './styles';
import { ILoginFormData } from './types';

export const LoginForm = () => {
  const [loginRequest, { isLoading }] = useLoginMutation();
  const { t } = useTranslation();
  const navigate = useNavigation();
  const { showNotificationMessage } = useNotistack();

  const validationSchema = buildValidationSchema(t);
  const methods = useForm<ILoginFormData>({
    resolver: zodResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: ILoginFormData) => {
    try {
      await loginRequest(data).unwrap();
      navigate(AdminPanelRoutesPaths.CASES_LIST);
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  return (
    <Div sx={styles.container}>
      <Typography align="center" variant="h1" sx={styles.title}>
        {t('auth.login.title')}
      </Typography>
      <FormProvider {...methods}>
        <Form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={styles.container}
        >
          <Div sx={styles.row}>
            <TextField
              {...register('email')}
              variant="outlined"
              label={t('auth.login.form.email.label')}
              placeholder={t('auth.login.form.email.placeholder')}
              error={Boolean(errors.email)}
              helperText={<>{errors.email?.message}</>}
              fullWidth
            />
          </Div>
          <Div sx={msx(styles.row, styles.last)}>
            <PasswordTextField
              field="password"
              variant="outlined"
              label={t('auth.login.form.password.label')}
              placeholder={t('auth.login.form.password.placeholder')}
              helperText={<>{errors.password?.message}</>}
              fullWidth
            />
          </Div>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            fullWidth
          >
            {t('auth.login.form.submit')}
          </Button>
        </Form>
      </FormProvider>
    </Div>
  );
};
