import { routes } from '@bvi/admin-panel/api-routes';
import {
  apiContainer,
  invalidateTagsList,
  provideTagsList,
  TagTypes,
  WithId,
} from '@bvi/admin-panel/shared/api';
import {
  ICaseListSearchQuery,
  ICreateCaseRequest,
  IGetCaseRequest,
  ITransitUserCaseRequest,
  IUpdateCaseRequest,
} from '@bvi/api-interfaces/request/case';
import {
  ICaseResponse,
  ICasesListResponse,
} from '@bvi/api-interfaces/response/case';

export const casesApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getCasesList: builder.query<ICasesListResponse, ICaseListSearchQuery>({
      query: ({ status, search, include, page }) => ({
        url: routes.adminCases.findAll(),
        params: {
          status,
          search,
          include,
          page,
        },
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.CASES_LIST),
    }),
    getCase: builder.query<ICaseResponse, IGetCaseRequest>({
      query: ({ id }) => ({
        url: routes.adminCases.findOne(id),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.CASE),
    }),
    createCase: builder.mutation<ICaseResponse, ICreateCaseRequest>({
      query: (data) => ({
        url: routes.adminCases.create(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateTagsList(TagTypes.CASES_LIST),
    }),
    updateCase: builder.mutation<ICaseResponse, WithId<IUpdateCaseRequest>>({
      query: ([id, data]) => ({
        url: routes.adminCases.update(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: invalidateTagsList(TagTypes.CASES_LIST, TagTypes.CASE),
    }),
    removeCase: builder.mutation<void, number>({
      query: (id: number) => ({
        url: routes.adminCases.remove(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateTagsList(TagTypes.CASES_LIST),
    }),
    transitUserCase: builder.mutation<void, WithId<ITransitUserCaseRequest>>({
      query: ([id, data]) => ({
        url: routes.adminUserCases.transit(id),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateTagsList(TagTypes.CASES_LIST),
    }),
  }),
});

export const {
  useGetCasesListQuery,
  useUpdateCaseMutation,
  useRemoveCaseMutation,
  useCreateCaseMutation,
  useGetCaseQuery,
  useTransitUserCaseMutation,
} = casesApi;
