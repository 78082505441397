export const styles = {
  horizontalScroll: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflowX: 'scroll',
  },
  chip: {
    m: '2px',
  },
};
