import { TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

import { ISavedSearchEntryData } from '@bvi/api-interfaces/response/case';
import { formatDate } from '@bvi/date-utils';

import { styles } from './styles';

export interface ISavedSearchEntryProperties {
  entry: ISavedSearchEntryData;
}

export const SavedSearchEntryRow: FC<ISavedSearchEntryProperties> = ({
  entry,
}) => {
  const name = `${entry.user.profile.firstName} ${entry.user.profile.lastName}`;

  return (
    <TableRow>
      <TableCell sx={styles.createdAtCell}>
        <Typography variant="mainColumn">
          {formatDate(entry.createdAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography fontWeight="lighter">{name}</Typography>
      </TableCell>
    </TableRow>
  );
};
