import { generatePath } from 'react-router-dom';

import { useGetClientsQuery } from '@bvi/admin-panel/entities/clients/api-slice';
import { selectClients } from '@bvi/admin-panel/entities/clients/selectors';
import { ClientListHeader } from '@bvi/admin-panel/feature/clients/ui/client-list-header';
import { ClientsTable } from '@bvi/admin-panel/feature/clients/ui/clients-table';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

export const ClientListPage = () => {
  const navigate = useNavigation();

  const { data: clients, isLoading } = useGetClientsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      data: selectClients(data),
      isLoading,
    }),
  });

  return (
    <>
      <ClientListHeader clientsNumber={clients.length} loading={isLoading} />
      <ClientsTable
        style={{ marginBottom: '40px' }}
        clients={clients}
        loading={isLoading}
        onClick={(event, id) =>
          navigate(
            generatePath(AdminPanelRoutesPaths.CLIENT_DETAILS, {
              id: String(id),
            }),
          )
        }
      />
    </>
  );
};
