export const styles = {
  clientLink: {
    textDecoration: 'none',
    display: 'inline-block',
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
};
