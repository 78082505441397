import { SxStyles } from '@bvi/mui-theme';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    mb: 4,
  },
  row: {
    width: 400,
    mb: 2,
  },
  last: {
    mb: 4,
  },
} satisfies SxStyles;
