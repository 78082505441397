import { Stack } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useGetCasesListQuery } from '@bvi/admin-panel/entities/cases/api-slice';
import {
  selectCasesList,
  selectCasesTotalCount,
} from '@bvi/admin-panel/entities/cases/selectors';
import { PendingCasesTable } from '@bvi/admin-panel/feature/pending-cases/ui/pending-cases-table';
import { PendingCasesTitle } from '@bvi/admin-panel/feature/pending-cases/ui/pending-cases-title';
import { CaseStatus } from '@bvi/api-interfaces/entity/case';
import { CaseQueryInclude } from '@bvi/api-interfaces/request/case';

import { styles } from './styles';

export const PendingCasesPage: FC = () => {
  const [count, setCount] = useState(0);
  const [parameters, setSearchParameter] = useSearchParams({
    search: '',
    page: '1',
  });

  const queryParameters = useMemo(
    () => ({
      status: CaseStatus.PENDING,
      search: parameters.get('search') ?? '',
      include: CaseQueryInclude.CLIENTS,
      page: Number.parseInt(parameters.get('page') ?? '1'),
    }),
    [parameters],
  );

  const { isFetching } = useGetCasesListQuery(queryParameters);

  const _count = useSelector(selectCasesTotalCount(queryParameters));
  const cases = useSelector(selectCasesList(queryParameters));

  useEffect(() => {
    /** We want to change count label only when it isn't undefined to prevent blinking */
    if (_count) {
      setCount(_count);
    }
  }, [_count]);

  const onPageChange = (page: number) => {
    if (isFetching) {
      return;
    }

    setSearchParameter({ page: page.toString() });
  };

  return (
    <Stack direction="column">
      <PendingCasesTitle count={count} sx={styles.title} />
      <PendingCasesTable
        sx={styles.table}
        count={count}
        page={queryParameters.page}
        cases={cases}
        isLoading={isFetching}
        onPageChange={onPageChange}
      />
    </Stack>
  );
};
