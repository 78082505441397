import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { usePrefetch } from '@bvi/admin-panel/entities/clients/api-slice';
import { CasesCreatePage } from '@bvi/admin-panel/pages/cases-create-page';
import { CasesEditPage } from '@bvi/admin-panel/pages/cases-edit-page';
import { CasesListPage } from '@bvi/admin-panel/pages/cases-list-page';
import { ClientDetailsPage } from '@bvi/admin-panel/pages/client-details-page';
import { ClientListPage } from '@bvi/admin-panel/pages/client-list-page';
import { PendingCasesPage } from '@bvi/admin-panel/pages/pending-cases-page';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { AdminPanelLayout } from '@bvi/admin-panel/widgets/layouts';

export interface IAdminPanelLayoutProperties {
  isAuthorized: boolean;
}

export const AdminPanelRoutes = () => {
  const prefetchCompanySizes = usePrefetch('getCompanySizes');
  const prefetchSubscriptionPlans = usePrefetch('getSubscriptionPlans');

  useEffect(() => {
    prefetchCompanySizes();
    prefetchSubscriptionPlans();
  }, []);

  return (
    <AdminPanelLayout>
      <Routes>
        <Route
          path={AdminPanelRoutesPaths.CASES_LIST}
          element={<CasesListPage />}
        />
        <Route
          path={AdminPanelRoutesPaths.CASES_CREATE}
          element={<CasesCreatePage />}
        />
        <Route
          path={AdminPanelRoutesPaths.CASES_EDIT}
          element={<CasesEditPage />}
        />
        <Route
          path={AdminPanelRoutesPaths.PENDING_CASES_LIST}
          element={<PendingCasesPage />}
        />
        <Route
          path={AdminPanelRoutesPaths.CLIENTS_LIST}
          element={<ClientListPage />}
        />
        <Route
          path={AdminPanelRoutesPaths.CLIENT_DETAILS}
          element={<ClientDetailsPage />}
        />
        <Route
          path="*"
          element={<Navigate to={AdminPanelRoutesPaths.CASES_LIST} replace />}
        />
      </Routes>
    </AdminPanelLayout>
  );
};
