import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetSubscriptionPlansQuery,
  useUpdateSubscriptionMutation,
} from '@bvi/admin-panel/entities/clients/api-slice';
import { selectSubscriptionPlans } from '@bvi/admin-panel/entities/clients/selectors';
import { IChangeSubscriptionFormProperties } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-subscription-tab/change-subscription-form/types';
import { IUpdateSubscriptionRequest } from '@bvi/api-interfaces/request/subscription';
import { isServerError } from '@bvi/axios-query';
import { Form, FormDatePicker } from '@bvi/common-components';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';

export const ChangeSubscriptionForm: FC<IChangeSubscriptionFormProperties> = ({
  subscription,
  onSuccess,
}) => {
  const { data: subscriptionPlans } = useGetSubscriptionPlansQuery(undefined, {
    selectFromResult: ({ data }) => ({ data: selectSubscriptionPlans(data) }),
  });

  const [updateSubscription, { isLoading, error }] =
    useUpdateSubscriptionMutation();
  const { t } = useTranslation();
  const { showNotificationMessage } = useNotistack();

  const validationSchema = buildValidationSchema(t);
  const methods = useForm<IUpdateSubscriptionRequest>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      subscriptionPlanId: subscription.plan.id,
      subscriptionExpiresAt: subscription.expiresAt,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: IUpdateSubscriptionRequest) => {
    await updateSubscription([subscription.id, data]);
    onSuccess?.();
  };

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
      );
    }
  }, [error, showNotificationMessage]);

  return (
    <FormProvider {...methods}>
      <Typography variant="h1" mb={2}>
        {t('subscriptions.edit.title')}
      </Typography>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} minWidth="400px">
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel
                id="select-subscription-plan"
                error={Boolean(errors.subscriptionPlanId)}
              >
                {t('clients.add.form.subscriptionPlan.label')}
              </InputLabel>
              <Select
                {...register('subscriptionPlanId', {
                  valueAsNumber: true,
                  required: true,
                })}
                label={t('clients.add.form.subscriptionPlan.label')}
                labelId="select-subscription-plan"
                placeholder={t('clients.add.form.subscriptionPlan.placeholder')}
                error={Boolean(errors.subscriptionPlanId)}
                defaultValue={subscription.plan.id}
              >
                {subscriptionPlans.map((subscriptionPlan) => (
                  <MenuItem
                    key={subscriptionPlan.id}
                    value={subscriptionPlan.id}
                  >
                    {subscriptionPlan.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.subscriptionPlanId && (
                <FormHelperText error>
                  {errors.subscriptionPlanId?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              valueFormat="jsDate"
              field="subscriptionExpiresAt"
              label={t('clients.add.form.subscriptionExpiresAt.label')}
              error={Boolean(errors.subscriptionExpiresAt)}
              helperText={<>{errors.subscriptionExpiresAt?.message}</>}
              minDate={dayjs(new Date())}
            />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
              autoFocus
            >
              {t('subscriptions.edit.form.submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};
