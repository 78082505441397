import { LoadingButton } from '@mui/lab';
import {
  Button,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { useTransitUserCaseMutation } from '@bvi/admin-panel/entities/cases/api-slice';
import { DeclineUserCaseForm } from '@bvi/admin-panel/feature/pending-cases/ui/pending-cases-table/decline-user-case-form';
import { ManagePendingCaseMenu } from '@bvi/admin-panel/feature/pending-cases/ui/pending-cases-table/manage-pending-case-menu';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { CaseEvents } from '@bvi/api-interfaces/entity/case';
import { ICaseListData } from '@bvi/api-interfaces/response/case';
import { PaperModal, Span } from '@bvi/common-components';
import { formatDate } from '@bvi/date-utils';

import { styles } from './styles';

export interface IPendingCasesTableRowProperties {
  case: ICaseListData;
}

export const PendingCasesTableRow: FC<IPendingCasesTableRowProperties> = ({
  case: _case,
}) => {
  const { t } = useTranslation();

  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);
  const handleOpenDeclineModal = () => setDeclineModalOpen(true);
  const handleCloseDeclineModal = () => setDeclineModalOpen(false);

  const { userCase, createdAt, description } = _case;

  const path =
    userCase &&
    generatePath(AdminPanelRoutesPaths.CLIENT_DETAILS, {
      id: String(userCase.creator.company.client.id),
    });

  const createdAtString = formatDate(createdAt);

  const [transitUserCase, { isLoading }] = useTransitUserCaseMutation();

  const username =
    userCase &&
    `${userCase.creator.profile.firstName} ${userCase.creator.profile.lastName}`;

  const handleApproveUserCase = () => {
    if (userCase) {
      transitUserCase([userCase.id, { event: CaseEvents.Approve }]);
    }
  };

  return (
    <TableRow>
      <TableCell width="8%">{userCase?.perCompanyId}</TableCell>
      <TableCell width="20%">
        {path && (
          <Span>
            <Span>{username}</Span>
            <Span
              dangerouslySetInnerHTML={{
                __html: t('common.commaSpaceSeparator'),
              }}
            />
            <Link to={path} style={styles.clientLink}>
              <Typography color="primary" fontWeight="bold">
                {userCase.creator.company.name}
              </Typography>
            </Link>
          </Span>
        )}
      </TableCell>
      <TableCell width="12%">{createdAtString}</TableCell>
      <TableCell width="30%" sx={styles.description}>
        <Tooltip title={description}>
          <Span>{description ?? ''}</Span>
        </Tooltip>
      </TableCell>
      <TableCell align="right" width="25%">
        <LoadingButton
          variant="text"
          size="small"
          loading={isLoading}
          disabled={!userCase}
          onClick={handleApproveUserCase}
          data-testid={`approve-case-${userCase?.id}`}
        >
          {t('pendingCases.list.actions.approve.caption')}
        </LoadingButton>
        <Button
          variant="text"
          size="small"
          color="error"
          disabled={!userCase}
          onClick={handleOpenDeclineModal}
          data-testid={`deny-case-${userCase?.id}`}
        >
          {t('pendingCases.list.actions.decline.caption')}
        </Button>
        {userCase && (
          <PaperModal
            open={isDeclineModalOpen}
            onClose={handleCloseDeclineModal}
          >
            <DeclineUserCaseForm
              userCase={userCase}
              onSuccess={handleCloseDeclineModal}
              onCancel={handleCloseDeclineModal}
            />
          </PaperModal>
        )}
      </TableCell>
      <TableCell>
        <ManagePendingCaseMenu case={_case} />
      </TableCell>
    </TableRow>
  );
};
