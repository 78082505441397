import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';

import { routes } from '@bvi/admin-panel/api-routes';
import { API_URL } from '@bvi/admin-panel/config';
import { AuthErrorsType } from '@bvi/api-interfaces/error/auth';
import { IErrorBaseResponse } from '@bvi/api-interfaces/response/base';
import { axiosBaseQuery } from '@bvi/axios-query';

import { AuthRoutesPaths } from '../constants/routes';

import { TagTypes } from './tag-types';

export const apiContainer = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(TagTypes),
  baseQuery: axiosBaseQuery({
    baseURL: API_URL,
    refreshTokenOptions: {
      shouldRefresh: (errorResponse: AxiosError<IErrorBaseResponse>) =>
        errorResponse.response?.data.error.type ===
        AuthErrorsType.INVALID_ACCESS_TOKEN,
    },
    async refreshTokenLogic(_error) {
      try {
        return await axios.post(API_URL + routes.adminsAuth.refreshToken());
      } catch {
        if (window.location.pathname !== AuthRoutesPaths.LOGIN) {
          window.location.href = AuthRoutesPaths.LOGIN;
        }
        return;
      }
    },
    onError: async (error) => {
      if (
        error.response?.status === 403 &&
        window.location.pathname !== AuthRoutesPaths.LOGIN
      ) {
        window.location.href = AuthRoutesPaths.LOGIN;
      }
    },
  }),
  endpoints: () => ({}),
});
