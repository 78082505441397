import * as Sentry from '@sentry/react';
import * as React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { ENVIRONMENT } from '@bvi/admin-panel/config';

export const initSentry = (
  dsn: string,
  environment: string,
  tracesSampleRate: number,
) => {
  if (ENVIRONMENT === 'development') {
    return;
  }

  Sentry.init({
    dsn,
    environment,
    enabled: environment !== 'development',
    release: 'latest',
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate,

    tracePropagationTargets: [/\.burnettindex\.com/gm],
    normalizeDepth: 10,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    profilesSampleRate: 0.2,
  });
};
