import { CaseStatus } from '@bvi/api-interfaces/entity/case';
import {
  AbuseDetailsKeys,
  AbuseDetailsLevel1VariantKeys,
  AbuseDetailsLevel2VariantKeys,
  AbuseDetailsLevel3VariantKeys,
  AbuseDetailsLevel4VariantKeys,
  AbuseDetailsLevel5VariantKeys,
  LegalDetailsExceptionsVariantsKey,
  LegalDetailsLiabilityDefenseVariantsKey,
  LegalDetailsPreparatorVariantsKey,
  PerpetratorInfoJobVariantKeys,
  PerpetratorInfoOrganizationVariantKeys,
  PerpetratorInfoReligionOrder,
  PerpetratorInfoSubsidiaryVariantKeys,
  ResolutionPublicityVariantsKey,
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoGenderVariantKeys,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import {
  AdditionalFilterParameter,
  CaseStatisticsLevel,
} from '@bvi/api-interfaces/request/case';

export default {
  title: 'Burnett Valuation Index',
  subtitle: 'Admin panel',
  header: {
    database: 'Burnett Index Database',
    pending_cases: 'Pending Cases',
    crate_case: 'Create Case',
    clients: 'Clients',
    logout: 'LogOut',
    profile: 'Profile',
  },
  user_menu: {
    logout: 'Logout',
  },
  auth: {
    login: {
      title: 'Log In',
      form: {
        email: {
          label: 'Email',
          placeholder: 'Enter your email',
        },
        password: {
          label: 'Password',
          placeholder: 'Enter your password',
        },
        submit: 'Log In',
      },
    },
  },
  clients: {
    list: {
      title: 'Clients',
      buttons: {
        addClient: 'Add New Client',
      },
      table: {
        columns: {
          clientName: 'Client Name',
          contactPerson: 'Contact Person',
          contactEmail: 'Contact Email',
          status: 'Status',
          numberOfCases: 'Number of Cases',
          numberOfUsers: 'Number of Users',
        },
      },
    },
    add: {
      title: 'Add New Client',
      subtitleCompany: 'Company',
      subtitleSubscription: 'Subscription',
      form: {
        name: {
          label: 'Name',
          placeholder: 'Enter company name',
        },
        companySize: {
          label: 'Size',
          placeholder: 'Choose company size',
        },
        contactPerson: {
          label: 'Contact Person',
          placeholder: 'Enter name of a contact person',
        },
        contactEmail: {
          label: 'Contact Email',
          placeholder: 'Enter email of a contact person',
        },
        subscriptionExpiresAt: {
          label: 'Expires at',
        },
        subscriptionPlan: {
          label: 'Plan',
          placeholder: 'Choose plan',
        },
        submit: 'Add',
      },
    },
    details: {
      buttons: {
        editCompany: {
          caption: 'Edit Company',
        },
        confirm: {
          caption: 'Confirm',
          question: 'Are you sure you want to confirm this client?',
        },
        block: {
          caption: 'Block',
          question: 'Are you sure you want to block this client?',
        },
        restore: {
          caption: 'Restore',
          question: 'Are you sure you want to restore this client?',
        },
      },
      table: {
        columns: {
          companySize: 'Company Size',
          subscriptionPlan: 'Subscription Plan',
          subscriptionExpiresAt: 'Subscription Expiration Date',
          status: 'Status',
          numberOfUsers: 'Number of Users',
          neverExpires: 'never',
        },
      },
      breadcrumbs: {
        clients: 'Clients',
      },
      tabs: {
        users: {
          title: 'Users',
        },
        cases: {
          title: 'Cases',
          table: {
            columns: {
              description: 'Description',
              status: 'Status',
            },
          },
        },
        savedSearches: {
          title: 'Searches',
          table: {
            columns: {
              searchDates: 'Search Dates',
              userName: 'User Name',
            },
          },
        },
        subscription: {
          title: 'Subscription',
        },
      },
    },
  },
  companies: {
    edit: {
      title: 'Edit Company',
      form: {
        submit: 'Save',
      },
    },
  },
  subscriptions: {
    buttons: {
      manage: {
        caption: 'Manage Subscription',
      },
    },
    edit: {
      title: 'Manage Subscription',
      form: {
        submit: 'Save',
      },
    },
  },
  users: {
    table: {
      columns: {
        name: 'User Name',
        email: 'Email',
        phone: 'Phone',
        status: 'Status',
        role: 'Role',
      },
    },
    actions: {
      move: {
        caption: 'Move to Another Company',
        titlePrefix: 'Move',
        titlePostfix: 'to Another Company',
      },
      confirm: {
        caption: 'Confirm',
        question: 'Are you sure you want to confirm this user?',
      },
      block: {
        caption: 'Block',
        question: 'Are you sure you want to block this user?',
      },
      restore: {
        caption: 'Restore',
        question: 'Are you sure you want to restore this user?',
      },
    },
    statuses: {
      waiting_confirmation: 'Waiting for Confirmation',
      confirmed: 'Confirmed',
      deleted: 'Blocked',
    },
  },
  cases: {
    list: {
      title: 'Burnett Index Database',
      buttons: {
        addCase: 'Add New Case',
        applyFilters: 'Search',
      },
      actions: {
        edit: {
          caption: 'Edit',
        },
        details: {
          caption: 'Details',
        },
        remove: {
          caption: 'Remove',
          question: 'Are you sure you want to remove this case?',
        },
      },
      table: {
        columns: {
          claimant: 'Claimant',
          name: 'Case / File Name',
          description: 'Description',
        },
        filters: {
          search: {
            label: 'Search',
            placeholder: 'Search by Case Name, Description',
          },
        },
      },
    },
    form: {
      title: 'Enter Claim / Case Information',
      breadcrumbs: {
        cases: 'Cases',
      },
      subtitle: 'Required fields are marked with *',
      range: {
        from: 'From',
        to: 'To',
      },
      caseInformation: {
        country: {
          label: 'Country',
          placeholder: 'Select country',
        },
        state: {
          label: 'State/Province',
          placeholder: 'Select state/province ',
        },
        region: {
          label: 'Region',
          placeholder: 'Select region',
        },
        damagesConsiderations: {
          label: 'Damages Considerations',
          placeholder: 'Select',
        },
        group: {
          label: 'Group or Class of Claimants',
          placeholder: 'Select',
        },
        title: 'Case/File Information',
        subtitle:
          'Does this entry involve a single claimant or a group of claimants?',
        claimantName: {
          label: 'Claimant Name',
          placeholder: 'Enters claimant name',
        },
        name: {
          label: 'Case/File No',
          placeholder: 'Enter case/file number',
        },
        claimantCount: '# in Group or class',
      },
      claimantInformation: {
        title: 'Claimant Information',
        gender: {
          label: 'Claimant Gender',
          placeholder: 'Select',
        },
        birthDate: {
          label: 'Birthdate',
          placeholder: 'Select',
        },
        ageAtStartOfAbuse: {
          label: 'Age at start of Abuse',
          placeholder: 'Select',
        },
        ageAtEndOfAbuse: {
          label: 'Age at end of Abuse',
          placeholder: 'Select',
        },
        ageCategory: {
          label: 'Age Category at Time of Abuse',
          placeholder: 'Select',
        },
        startedAbuseAt: {
          label: 'Dates / Period of Abuse',
          placeholder: 'From',
        },
        endOfAbuseAt: {
          placeholder: 'To',
        },
      },
      perpetratorInformation: {
        title: 'Perpetrator Information',
        name: {
          label: 'Name of Perpetrator',
          placeholder: 'Enter name of perpetrator',
        },
        job: {
          label: 'Perpetrator`s Job Title/Position',
          placeholder: 'Select',
        },
        religionOrder: {
          label: 'Religious Order',
          placeholder: 'Select',
        },
        organization: {
          label: 'Organization/Church/Employer of Perpetrator',
          placeholder: 'Select',
        },
        organizationType: {
          label: 'Organization Type',
          placeholder: 'Select',
        },
        subsidiary: {
          label: 'Subsidiary',
          placeholder: 'Select',
        },
      },
      typeOfAbuse: {
        title: 'Type/Nature/Details of Abuse',
        subtitle: 'Type/Nature/Details of Abuse (all that apply)',
        details: {
          label: 'Are abuse details available?',
        },
        level1: {
          label: 'LEVEL 1: ',
        },
        level2: {
          label: 'LEVEL 2: ',
        },
        level3: {
          label: 'LEVEL 3: ',
        },
        level4: {
          label: 'LEVEL 4: ',
        },
        level5: {
          label: 'LEVEL 5: ',
        },
        numberOfIncidents: {
          label: 'Number of Incidents of Abuse',
          placeholder: 'Enter',
        },
        numberOfPerpetrators: {
          label: 'Number of Perpetrators',
          placeholder: 'Enter',
        },
        isOtherActive: {
          label: 'OTHER:',
        },
      },
      legalDetails: {
        title: 'Legal Issues/Details',
        lawsuitFiled: {
          label: 'Lawsuit Filed',
        },
        hasClaimantRetainedAnAttorney: {
          label: 'Has Claimant Retained an Attorney? ',
        },
        liabilityDefense: {
          label: 'Liability Defenses (choose all that apply)',
          placeholder: 'Select',
        },
        perpetrator: {
          label: 'Perpetrator',
        },
        exceptions: {
          label: 'Exceptions/Rebuttals to Liability Defenses',
          placeholder: 'Select',
        },
        damagesComment: {
          label: 'Damages Comments',
        },
        otherLifeStress: {
          label: 'Claimant`s Other Life Stresses',
        },
        description: {
          label: 'Other Claim Details',
        },
      },
      resolutionDetails: {
        label: 'Enter Resolution Details',
        title: 'Resolution Details',
        resolutionDate: {
          label: 'Resolution Date',
          placeholder: 'Select',
        },
        totalResolutionAmount: {
          label: 'Total Resolution Amount',
        },
        claimantsCount: {
          label: 'No. of Claimants',
        },
        amountPerClaimant: {
          label: 'Amount Per Claimant',
        },
        totalDefenseCosts: {
          label: 'Total Defense Costs',
        },
        typeOfResolution: {
          label: 'Type of Resolution',
          placeholder: 'Select',
        },
        isPublic: {
          label: 'Is This Resolution',
        },
        note: 'You have indicated this is a confidential resolution. The contents of this claim entry, including all claim details and the resolution value, will remain confidential and will not be disclosed. The claim information will only be used in the averaging and metrics of claim valuation in the Burnett Index.',
      },
      reset: 'Clear All',
      search: 'Search Using this Criteria',
      submit: 'Save',
      submitAndSearch: 'Save & Run Search',
    },
    common: {
      status: {
        [CaseStatus.APPROVED]: 'Accepted',
        [CaseStatus.CLOSED]: 'Closed',
        [CaseStatus.DRAFT]: 'Draft',
        [CaseStatus.PENDING]: 'Pending',
        [CaseStatus.DENIED]: 'Declined',
      } satisfies Record<CaseStatus, string>,
    },
  },
  pendingCases: {
    list: {
      title: 'Pending Cases',
      actions: {
        approve: {
          caption: 'Approve',
        },
        decline: {
          caption: 'Decline',
        },
        remove: {
          caption: 'Remove',
          question: 'Are you sure you want to remove this case?',
        },
      },
      table: {
        columns: {
          number: '#',
          user: 'User',
          createdAt: 'Request Date',
          description: 'Description',
        },
      },
    },
    declineForm: {
      title: 'Enter Decline Reason',
      fields: {
        declineReason: {
          label: 'Decline Reason',
        },
      },
      buttons: {
        decline: {
          caption: 'Decline',
        },
        close: {
          caption: 'Close',
        },
      },
    },
  },
  confidentiality: {
    public: 'Public',
    confidential: 'Confidential',
  },
  claimantTypeItems: {
    single: 'Single Claimant',
    group: 'Group or Class of Claimants',
  },
  abuseTypeLevelSwitch: {
    specified: 'Specified',
    unspecified: 'Unspecified',
  },
  agreementValues: {
    yes: 'Yes',
    no: 'No',
  },
  caseStatus: {
    [CaseStatus.APPROVED]: 'Approved',
    [CaseStatus.CLOSED]: 'Closed',
    [CaseStatus.DENIED]: 'Denied',
    [CaseStatus.DRAFT]: 'Draft',
    [CaseStatus.PENDING]: 'Pending',
  },
  abuseTypeLevels: {
    [AbuseDetailsKeys.DETAIL_AVAILABLE]: 'detail-available',
    [AbuseDetailsKeys.LEVEL_1]: 'Level 1',
    [AbuseDetailsKeys.LEVEL_2]: 'Level 2',
    [AbuseDetailsKeys.LEVEL_3]: 'Level 3',
    [AbuseDetailsKeys.LEVEL_4]: 'Level 4',
    [AbuseDetailsKeys.LEVEL_5]: 'Level 5',
    [CaseStatisticsLevel.LEVEL_1]: 'Level 1',
    [CaseStatisticsLevel.LEVEL_2]: 'Level 2',
    [CaseStatisticsLevel.LEVEL_3]: 'Level 3',
    [CaseStatisticsLevel.LEVEL_4]: 'Level 4',
    [CaseStatisticsLevel.LEVEL_5]: 'Level 5',
    [AbuseDetailsKeys.LEVEL_1_UNSPECIFIED]: 'level-1-unspecified',
    [AbuseDetailsKeys.LEVEL_2_UNSPECIFIED]: 'level-2-unspecified',
    [AbuseDetailsKeys.LEVEL_3_UNSPECIFIED]: 'level-3-unspecified',
    [AbuseDetailsKeys.LEVEL_4_UNSPECIFIED]: 'level-4-unspecified',
    [AbuseDetailsKeys.LEVEL_5_UNSPECIFIED]: 'level-5-unspecified',
    [AbuseDetailsKeys.OTHER]: 'Other',
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: 'number-of-incidents-low',
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: 'number-of-incidents-high',
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: 'number-of-perpetrators-low',
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]:
      'number-of-perpetrators-high',
  },
  abuseTypeItems: {
    [AbuseDetailsLevel1VariantKeys.SUGGESTIVE_SEXUAL_TALK]:
      'Suggestive Sexual Talk',
    [AbuseDetailsLevel1VariantKeys.KISSING_ANG_HUGGING]:
      'Kissing/Kissing and Hugging',
    [AbuseDetailsLevel1VariantKeys.TOUCHING_NON_PRIVATE_PARTS]:
      'Touched Non-Private Parts',
    [AbuseDetailsLevel1VariantKeys.PROVIDING_VIEWING_PORNOGRAPHY]:
      'Providing/Viewing Pornography',
    [AbuseDetailsLevel1VariantKeys.EXPOSURE_OF_PERPETRATOR_TO_GENITALS]:
      'Exposure of Perpetrator’s Genitals',
    [AbuseDetailsLevel1VariantKeys.BUTTOCKS_FONDING]: 'Buttocks Fondling',
    [AbuseDetailsLevel2VariantKeys.WITNESS_PERPETRATOR_MASTURBATE]:
      'Witness Perpetrator Masturbate',
    [AbuseDetailsLevel2VariantKeys.PROVIDED_ALCOHOL_DRUGS]:
      'Provided Alcohol/Drugs ',
    [AbuseDetailsLevel2VariantKeys.FRENCH_KISSING]: '“French Kissing”',
    [AbuseDetailsLevel2VariantKeys.BREAST_FONDLING]: 'Breast Fondling ',
    [AbuseDetailsLevel2VariantKeys.PRESSING_GENITALS_AGAINST_CLAIMANT]:
      'Pressing Genitals Against Claimant',
    [AbuseDetailsLevel2VariantKeys.CLAIMANT_IS_UNDRESSED_NAKED]:
      'Claimant is Undressed/Naked',
    [AbuseDetailsLevel2VariantKeys.OVER_THE_CLOTHES_GENITAL_FONDLING]:
      'Over the Clothes Genital Fondling',
    [AbuseDetailsLevel3VariantKeys.MUTUAL_SELF_MASTURBATION]:
      'Mutual Self-Masturbation',
    [AbuseDetailsLevel3VariantKeys.MUTUAL_MASTURBATION_OF_EACH_OTHER]:
      'Mutual Masturbation of Each Other ',
    [AbuseDetailsLevel3VariantKeys.SEX_TOYS_PARAPHERNALIA]:
      'Sex Toys/Paraphernalia ',
    [AbuseDetailsLevel3VariantKeys.COERCED_MASTURBATION_OF_PERPETRATOR]:
      'Coerced Masturbation of Perpetrator',
    [AbuseDetailsLevel3VariantKeys.SKIN_TO_SKIN_GENITAL_FONDLING]:
      'Skin-to-Skin Genital Fondling ',
    [AbuseDetailsLevel4VariantKeys.ORAL_SEX_UPON_CLAIMANT]:
      'Oral Sex Upon Claimant',
    [AbuseDetailsLevel4VariantKeys.ATTEMPTED_SODOMY]: 'Attempted Sodomy',
    [AbuseDetailsLevel4VariantKeys.ANAL_DIGITAL_PENETRATION]:
      'Anal Digital Penetration ',
    [AbuseDetailsLevel4VariantKeys.WEAPON_INTIMIDATION]: 'Weapon Intimidation',
    [AbuseDetailsLevel4VariantKeys.VAGINAL_DIGITAL_PENETRATION]:
      'Vaginal Digital Penetration',
    [AbuseDetailsLevel4VariantKeys.ORAL_ANAL_STIMULATION_OF_CLAIMANT]:
      'Oral/Anal Stimulation of Claimant',
    [AbuseDetailsLevel4VariantKeys.COERCED_ORAL_SEX_UPON_PERPETRATOR]:
      'Coerced Oral Sex Upon Perpetrator',
    [AbuseDetailsLevel5VariantKeys.ANAL_SODOMY]: 'Anal Sodomy',
    [AbuseDetailsLevel5VariantKeys.RAPE]: 'Rape',
    [AbuseDetailsLevel5VariantKeys.VAGINAL_INTERCOURSE]: 'Vaginal Intercourse',
    [AbuseDetailsLevel5VariantKeys.SEX_TRAFFICKING]: 'Sex Trafficking',
    [AbuseDetailsLevel5VariantKeys.COERCED_ORAL_ANAL_STIMULATION_OF_PERPETRATOR]:
      'Coerced Oral/Anal Stimulation of Perpetrator',
    [AbuseDetailsLevel5VariantKeys.CREATION_OF_CHILD_PORNOGRAPHY_USING_CLAIMANT]:
      'Creation of Child Pornography Using Claimant',
    [AbuseDetailsLevel1VariantKeys.UNSPECIFIED]: 'Unspecified',
  },
  perpetrator: {
    [LegalDetailsPreparatorVariantsKey.ADMITTED_ABUSE]: 'Admitted Abuse',
    [LegalDetailsPreparatorVariantsKey.OTHERWISE_DEEMED_GUILTY]:
      'Otherwise Deemed Guilty',
    [LegalDetailsPreparatorVariantsKey.DENIED_ABUSE]: 'Denied Abuse',
    [LegalDetailsPreparatorVariantsKey.DECEASED_WHEN_ACCUSED]:
      'Deceased When Accused',
    [LegalDetailsPreparatorVariantsKey.CONVICTED]: 'Convicted',
  },
  gender: {
    [VictimInfoGenderVariantKeys.ALL]: 'All',
    [VictimInfoGenderVariantKeys.FEMALE]: 'Female',
    [VictimInfoGenderVariantKeys.MALE]: 'Male',
    [VictimInfoGenderVariantKeys.UNKNOWN]: 'Unknown',
  },
  ageCategory: {
    [VictimInfoAgeCategoryVariantKeys.ADULT]: 'Adult',
    [VictimInfoAgeCategoryVariantKeys.MINOR]: 'Minor',
    [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]: 'Minor to adult',
    [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]: 'Teen to adult',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: 'Prepubescent',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
      'Prepubescent to adult',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
      'Prepubescent to teen',
    [VictimInfoAgeCategoryVariantKeys.TEEN]: 'Teen',
  },
  ageCategoryDescription: {
    [VictimInfoAgeCategoryVariantKeys.ADULT]: '18 and Over',
    [VictimInfoAgeCategoryVariantKeys.MINOR]:
      'Under Age 18 but the age may be unknown as yet',
    [VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT]:
      'Period of abuse includes unknown specific ages under age 18 but the period of abuse was known to span into ages 18 and over',
    [VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT]:
      'Period of abuse includes teenage years (13 to 17) and spans into ages 18 and over',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: 'Under Age 13',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN]:
      'Age range during period of abuse spanning years when the victim was under age 13 to when victim was into the teenage years but under age 18',
    [VictimInfoAgeCategoryVariantKeys.TEEN]: 'Age 13 to Under Age 18',
    [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT]:
      'Age range during period of abuse spanning years when the victim was under age 13 and spanning into ages 18 and over',
  },
  jobs: {
    [PerpetratorInfoJobVariantKeys.COACH]: 'Coach',
    [PerpetratorInfoJobVariantKeys.ELDER]: 'Elder',
    [PerpetratorInfoJobVariantKeys.FAMILY_MEMBER]: 'Family member',
    [PerpetratorInfoJobVariantKeys.MAINTENANCE]: 'Maintenance',
    [PerpetratorInfoJobVariantKeys.MINISTER]: 'Minister',
    [PerpetratorInfoJobVariantKeys.MONK]: 'Monk',
    [PerpetratorInfoJobVariantKeys.OTHER]: 'Other',
    [PerpetratorInfoJobVariantKeys.PRIEST]: 'Priest',
    [PerpetratorInfoJobVariantKeys.SISTER_NUN]: 'Sister nun',
    [PerpetratorInfoJobVariantKeys.STAFF]: 'Staff',
    [PerpetratorInfoJobVariantKeys.TEACHER]: 'Teacher',
  },
  religionOrders: {
    [PerpetratorInfoReligionOrder.BENEDICTINES]: 'Benedictines',
    [PerpetratorInfoReligionOrder.DOMINICANS]: 'Dominicans',
    [PerpetratorInfoReligionOrder.HOLY_CROSS]: 'Holy cross',
    [PerpetratorInfoReligionOrder.JESUITS]: 'Jesuits',
  },
  organizations: {
    [PerpetratorInfoOrganizationVariantKeys.BAPTIST_CHURCH]: 'Baptist church',
    [PerpetratorInfoOrganizationVariantKeys.BOY_SCOUTS_OF_AMERICA]:
      'Boy scouts of america',
    [PerpetratorInfoOrganizationVariantKeys.EPISCOPAL_CHURCH]:
      'Episcopal church',
    [PerpetratorInfoOrganizationVariantKeys.ROMAN_CATHOLIC_CHURCH]:
      'Roman catholic church',
  },
  subsidiaries: {
    [PerpetratorInfoSubsidiaryVariantKeys.ARCHDIOCESE_OF_NEW_YORK]:
      'Archdiocese of new york',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_ALBANY]:
      'Diocese of albany',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_BUFFALO]:
      'Diocese of buffalo',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_OGDENSBURG]:
      'Diocese of ogdensburg',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_ROCHESTER]:
      'Diocese of rochester',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_ROCKVILLE_CENTRE]:
      'Diocese of rockville centre',
    [PerpetratorInfoSubsidiaryVariantKeys.DIOCESE_OF_SYRACUSE]:
      'Diocese of syracuse',
  },
  liabilities: {
    [LegalDetailsLiabilityDefenseVariantsKey.STATUTE_OF_LIMITATIONS]:
      'Statute of limitations',
    [LegalDetailsLiabilityDefenseVariantsKey.STATUTE_OF_REPOSE]:
      'Statute of repose',
    [LegalDetailsLiabilityDefenseVariantsKey.CHARITABLE_IMMUNITY]:
      'Charitable immunity',
    [LegalDetailsLiabilityDefenseVariantsKey.CONSENSUAL]: 'Consensual',
    [LegalDetailsLiabilityDefenseVariantsKey.ADULT_RELATION_SHIP]:
      'Adult relation ship',
    [LegalDetailsLiabilityDefenseVariantsKey.FALSE_ACCUSATION]:
      'False accusation',
    [LegalDetailsLiabilityDefenseVariantsKey.FIRST_AMENDMENT]:
      'First amendment',
    [LegalDetailsLiabilityDefenseVariantsKey.UNFORESEEN_THIRD_PARTY_ATTACK]:
      'Unforeseen third party attack',
    [LegalDetailsLiabilityDefenseVariantsKey.NONE]: 'None',
  },
  yesNo: {
    [YesNoVariantsKey.YES]: 'Yes',
    [YesNoVariantsKey.NO]: 'No',
    null: 'N/A',
  },
  exceptions: {
    [LegalDetailsExceptionsVariantsKey.NY_CHILD_VICTIM_ACT]:
      'NY child victim act',
    [LegalDetailsExceptionsVariantsKey.NJ_STATUTE_OF_LIMITATIONS_WINDOW]:
      'NJ statute of limitations window',
  },
  error: {
    form: {
      required: 'This field is required',
      email: 'Please enter a valid email address',
      password: {
        invalid: 'Invalid Password',
      },
      short: '{{field}} must contain at least {{count}} character(s)',
      long: '{{field}} must contain at most {{count}} character(s)',
    },
    api: {
      UNKNOWN_SERVER_ERROR: 'Unknown server error',
      INVALID_CREDENTIALS: 'Invalid Credentials',
      INCORRECT_DATA: 'Incorrect data',
      INCORRECT_FILTER: 'Incorrect filter value: {{field}}. Reset this field?',
      USER_NOT_FOUND: 'User not found',
      USER_ALREADY_EXISTS: 'Email already used',
      RESET_PASSWORD_TOKEN_NOT_FOUND:
        'Incorrect token. You might have opened the incorrect link or the link has expired.',
      CANNOT_FIND_CASE: 'Cannot find case',
      CANNOT_FIND_USER_CASE: 'Cannot find user case',
      COMPANY_ALREADY_EXISTS: 'Company with such name already exists',
      CANNOT_CREATE_RESOLUTION: 'Cannot create resolution',
    },
  },
  dialog: {
    confirm: 'Yes',
    cancel: 'Cancel',
  },
  captions: {
    notAvailable: 'N/A',
  },
  search: {
    title: 'Search Results',
    caseTable: {
      // fileId: 'File ID #',
      claimNumber: 'Claim Number',
      needsReview: 'Needs Review?',
      country: 'Country',
      region: 'Region',
      state: 'State/Province',
      organizationType: 'Organization Type',
      resolutionType: 'Type of Resolution',
      gender: 'Gender',
      organization: 'Organization/Church/Employer',
      damagesComment: 'Damages Comments',
      subsidiary: 'Subsidiary Organization',
      resolutionDate: 'Resolution Date',
      resolutionAmount: 'Total Resolution Value',
      numberOfVictims: 'Number of Victims',
      amountPerVictim: 'Settlement per Victim',
      group: 'Group Settlement?',
      defenseCosts: 'Defense Costs',
      ageOfAbuse: 'Ages',
      ageCategory: 'Age Category',
      abusePeriod: 'Period of Abuse',
      instances: 'Number of Incidents',
      perpetrators: 'Number of Perpetrators',
      liabilityDefenses: 'Defenses',
      exceptions: 'Exceptions/Rebuttals to Defenses',
      otherStresses: 'Other Life Stressors',
      jobTitles: "Perpetrator's Job Title or Position",
      order: 'Religious Order',
      victimBirthDate: "Victim's Date of Birth",
      resolutionNotes: 'Resolution Details and Commentary',
      damages: 'Damages',
      publicOrConfidential: 'Public or Confidential',
      hasAttorney: 'Victim Retained Attorney?',
      perpetratorDeny: 'Did Perp. Deny?',
      lawsuit: 'Lawsuit',
      notes: 'Claim Notes',
      level1: 'Level 1',
      level2: 'Level 2',
      level3: 'Level 3',
      level4: 'Level 4',
      level5: 'Level 5',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    results: {
      title: 'Search Results',
      pdfButton: 'Generate PDF Report',
      saveSearchButton: 'Save Search',
      empty: 'No data for construction',
      notificationResetButton: 'Reset',
      additionalFilters: {
        [AdditionalFilterParameter.AMOUNT_LOW]: 'Settlement Range',
        [AdditionalFilterParameter.AMOUNT_HIGH]: 'Settlement Range',
      },
    },
  },
  [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: {
    [ResolutionPublicityVariantsKey.CONFIDENTIAL]: 'Confidential',
    [ResolutionPublicityVariantsKey.PUBLIC]: 'Public',
  },
  common: {
    commaSpaceSeparator: ',&nbsp;',
  },
} as const;
