import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IPendingCasesTitleProperties } from '@bvi/admin-panel/feature/pending-cases/ui/pending-cases-title/types';

export const PendingCasesTitle: FC<IPendingCasesTitleProperties> = ({
  count,
  sx,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="center"
      pt={4}
      sx={sx}
    >
      <Typography variant="h1" pr={2}>
        {t('pendingCases.list.title')}
      </Typography>
      <Typography
        variant="h1"
        color="text.secondary"
        data-testid="pending-cases-list_count"
      >
        {count}
      </Typography>
    </Stack>
  );
};
