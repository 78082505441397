import omit from 'lodash/omit';
import { stringify } from 'qs';

import { ISearchFormDataSchema, mapFormDataToSubmit } from '@bvi/cases-search';

export const isEqualParameters = (
  formData: ISearchFormDataSchema,
  searchParameters: Record<keyof ISearchFormDataSchema, string>,
) => {
  const preparedFormData = stringify(mapFormDataToSubmit(formData), {
    arrayFormat: 'comma',
  });
  const preparedSearchParameters = stringify(
    omit(searchParameters, ['page', 'limit']),
    {
      arrayFormat: 'comma',
    },
  );

  return preparedFormData === preparedSearchParameters;
};
