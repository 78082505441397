import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddClientForm } from '@bvi/admin-panel/feature/clients/ui/add-client-form';
import { IClientListHeaderProperties } from '@bvi/admin-panel/feature/clients/ui/client-list-header/types';
import { PaperModal, Span } from '@bvi/common-components';
import { theme } from '@bvi/mui-theme';

export const ClientListHeader: FC<IClientListHeaderProperties> = ({
  clientsNumber,
  loading,
}) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      my={1}
    >
      <Typography variant="h1" my={3}>
        <Span>{t('clients.list.title')}</Span>
        {loading ? (
          <Skeleton sx={{ ml: '0.5em', display: 'inline-block' }} width="2em" />
        ) : (
          <Span
            data-testid="clients.list.count"
            sx={{ ml: '0.5em', color: theme.palette.grey['200'] }}
          >
            {clientsNumber}
          </Span>
        )}
      </Typography>
      <Button variant="outlined" onClick={handleOpen}>
        {t('clients.list.buttons.addClient')}
      </Button>
      <PaperModal open={isOpen} onClose={handleClose}>
        <AddClientForm onSuccess={handleClose} />
      </PaperModal>
    </Stack>
  );
};
