import { routes } from '@bvi/admin-panel/api-routes';
import {
  apiContainer,
  getItemTag,
  getItemTags,
  getListTag,
  invalidateTags,
  provideTagsList,
  TagTypes,
  WithId,
} from '@bvi/admin-panel/shared/api';
import {
  ICreateClientRequest,
  IUpdateClientRequest,
} from '@bvi/api-interfaces/request/client';
import { IUpdateCompanyRequest } from '@bvi/api-interfaces/request/company';
import { IUpdateSubscriptionRequest } from '@bvi/api-interfaces/request/subscription';
import { getResponseData } from '@bvi/api-interfaces/response';
import {
  ISavedSearchListResponse,
  IUserCaseWithCaseListResponse,
} from '@bvi/api-interfaces/response/case';
import {
  IClientWithCompanyListResponse,
  IClientWithCompanyResponse,
} from '@bvi/api-interfaces/response/client';
import { ICompanyWithUsersResponse } from '@bvi/api-interfaces/response/company';
import { ICompanySizeListResponse } from '@bvi/api-interfaces/response/company-size';
import { ISubscriptionResponse } from '@bvi/api-interfaces/response/subscription';
import { ISubscriptionPlanListResponse } from '@bvi/api-interfaces/response/subscription-plan';

export const clientsApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<IClientWithCompanyListResponse, void>({
      query: () => ({
        url: routes.adminClients.find(),
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...getItemTags(TagTypes.CLIENTS, getResponseData(result)),
              getListTag(TagTypes.CLIENTS),
            ]
          : [getListTag(TagTypes.CLIENTS)],
    }),

    createClient: builder.mutation<
      IClientWithCompanyResponse,
      ICreateClientRequest
    >({
      query: (data) => ({
        url: routes.adminClients.createOne(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.CLIENTS)),
    }),

    updateClient: builder.mutation<
      IClientWithCompanyResponse,
      WithId<IUpdateClientRequest>
    >({
      query: ([id, data]) => ({
        url: routes.adminClients.updateById(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: (result) =>
        result
          ? [getItemTag(TagTypes.CLIENTS, getResponseData(result).id)]
          : [],
    }),

    getCompanyCases: builder.query<IUserCaseWithCaseListResponse, number>({
      query: (id) => ({
        url: routes.clientCases.findByCompanyId(id),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.CASES_LIST),
    }),

    getCompanySizes: builder.query<ICompanySizeListResponse, void>({
      query: () => ({
        url: routes.companies.getCompanySizes(),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.COMPANY_SIZES),
    }),

    updateCompany: builder.mutation<
      ICompanyWithUsersResponse,
      WithId<IUpdateCompanyRequest>
    >({
      query: ([id, data]) => ({
        url: routes.adminCompanies.updateById(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.CLIENTS)),
    }),

    getSubscriptionPlans: builder.query<ISubscriptionPlanListResponse, void>({
      query: () => ({
        url: routes.adminSubscriptions.getSubscriptionPlans(),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.SUBSCRIPTION_PLANS),
    }),

    updateSubscription: builder.mutation<
      ISubscriptionResponse,
      WithId<IUpdateSubscriptionRequest>
    >({
      query: ([id, data]) => ({
        url: routes.adminSubscriptions.updateById(id),
        method: 'PATCH',
        data,
      }),
      invalidatesTags: invalidateTags(getListTag(TagTypes.CLIENTS)),
    }),

    getClientSavedSearches: builder.query<ISavedSearchListResponse, number>({
      query: (id) => ({
        url: routes.savedSearchesAdmin.findByCompanyId(id),
        method: 'GET',
      }),
      providesTags: provideTagsList(TagTypes.SAVED_SEARCHES),
    }),
  }),
});

export const {
  useGetClientsQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useGetCompanyCasesQuery,
  useGetCompanySizesQuery,
  useUpdateCompanyMutation,
  useGetSubscriptionPlansQuery,
  useUpdateSubscriptionMutation,
  usePrefetch,
  useGetClientSavedSearchesQuery,
} = clientsApi;
