import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetCompanySizesQuery,
  useUpdateCompanyMutation,
} from '@bvi/admin-panel/entities/clients/api-slice';
import { selectCompanySizes } from '@bvi/admin-panel/entities/clients/selectors';
import { IEditCompanyFormProperties } from '@bvi/admin-panel/feature/clients/ui/edit-company-form/types';
import { IUpdateCompanyRequest } from '@bvi/api-interfaces/request/company';
import { isServerError } from '@bvi/axios-query';
import { Form } from '@bvi/common-components';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';

export const EditCompanyForm: FC<IEditCompanyFormProperties> = ({
  company,
  onSuccess,
}) => {
  const { data: companySizes } = useGetCompanySizesQuery(undefined, {
    selectFromResult: ({ data }) => ({ data: selectCompanySizes(data) }),
  });

  const [updateCompanyRequest, { isLoading, error }] =
    useUpdateCompanyMutation();
  const { t } = useTranslation();
  const { showNotificationMessage } = useNotistack();

  const validationSchema = buildValidationSchema(t);
  const methods = useForm<IUpdateCompanyRequest>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: company.name,
      contactPerson: company.contactPerson,
      contactEmail: company.contactEmail,
      companySizeId: company.size.id,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: IUpdateCompanyRequest) => {
    await updateCompanyRequest([company.id, data]);
    onSuccess?.();
  };

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
      );
    }
  }, [error, showNotificationMessage]);

  return (
    <FormProvider {...methods}>
      <Typography variant="h1" mb={2}>
        {t('companies.edit.title')}
      </Typography>
      <Form noValidate onSubmit={handleSubmit(onSubmit)} tabIndex={1}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              {...register('name')}
              variant="outlined"
              fullWidth
              label={t('clients.add.form.name.label')}
              placeholder={t('clients.add.form.name.placeholder')}
              error={Boolean(errors.name)}
              helperText={<>{errors.name?.message}</>}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel
                id="select-company-size"
                error={Boolean(errors.companySizeId)}
              >
                {t('clients.add.form.companySize.label')}
              </InputLabel>
              <Select
                {...register('companySizeId', { valueAsNumber: true })}
                label={t('clients.add.form.companySize.label')}
                labelId="select-company-size"
                placeholder={t('clients.add.form.companySize.placeholder')}
                error={Boolean(errors.companySizeId)}
                defaultValue={company.size.id}
              >
                {companySizes.map((companySize) => (
                  <MenuItem key={companySize.id} value={companySize.id}>
                    {companySize.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.companySizeId && (
                <FormHelperText error>
                  {errors.companySizeId?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('contactPerson')}
              variant="outlined"
              fullWidth
              label={t('clients.add.form.contactPerson.label')}
              placeholder={t('clients.add.form.contactPerson.placeholder')}
              error={Boolean(errors.contactPerson)}
              helperText={<>{errors.contactPerson?.message}</>}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('contactEmail')}
              variant="outlined"
              fullWidth
              label={t('clients.add.form.contactEmail.label')}
              placeholder={t('clients.add.form.contactEmail.placeholder')}
              error={Boolean(errors.contactEmail)}
              helperText={<>{errors.contactEmail?.message}</>}
            />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
              autoFocus
            >
              {t('companies.edit.form.submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};
