import { IBaseProperties } from '@bvi/api-interfaces/entity/base';
import { ICompanyWithUsers } from '@bvi/api-interfaces/entity/company';
import { ISubscription } from '@bvi/api-interfaces/entity/subscription';

export interface IClientBase extends IBaseProperties {
  status: ClientStatus;
}

export interface IClientWithSubscription extends IClientBase {
  subscription: ISubscription;
}

export interface IClient extends IClientWithSubscription {
  company: ICompanyWithUsers;
}

export enum ClientStatus {
  Unconfirmed = 'unconfirmed',
  Active = 'active',
  Archived = 'archived',
}
