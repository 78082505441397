import { Chip, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Div } from '@bvi/common-components';

import { styles } from './styles';

export interface IAbuseLevelsProperties {
  variants?: Array<string>;
}

export const AbuseLevels: FC<IAbuseLevelsProperties> = ({ variants }) => {
  const { t } = useTranslation();

  if (!variants) {
    return null;
  }

  const formattedVariants = variants
    .map((variant) => t(`abuseTypeItems.${variant}`))
    .join(', ');

  return (
    <Tooltip title={formattedVariants}>
      <Div sx={styles.horizontalScroll}>
        {variants.map((value) => (
          <Chip
            key={value}
            label={t(`abuseTypeItems.${value}`)}
            size="small"
            sx={styles.chip}
          />
        ))}
      </Div>
    </Tooltip>
  );
};
