import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { parse } from 'qs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { selectCurrentUser } from '@bvi/admin-panel/entities/admin-auth/selectors';
import { useLazyGetStatisticsQuery } from '@bvi/admin-panel/entities/search/api-slice';
import { selectStatistic } from '@bvi/admin-panel/entities/search/selectors';
import { PAGE_SIZE_OPTIONS } from '@bvi/admin-panel/feature/search/lib/constants';
import { IAdminSearchFilterFormData } from '@bvi/admin-panel/feature/search/lib/types';
import { useSearchNotification } from '@bvi/admin-panel/feature/search/lib/useSearchNotification';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { Search } from '@bvi/admin-panel/widgets/search';
import {
  EIncludeExcludeKeys,
  EIncludeExcludeValue,
} from '@bvi/api-interfaces/entity/case-property';
import { isServerError } from '@bvi/axios-query';
import {
  buildAgeCategoryQuery,
  searchFormPropertySchemas,
} from '@bvi/cases-search';
import { useNavigation } from '@bvi/navigation-utils';

enum COMMON {
  DEFAULT = 'default',
}

export const CasesListPage = () => {
  const location = useLocation();
  const navigate = useNavigation();
  const currentUser = useSelector(selectCurrentUser());
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof IAdminSearchFilterFormData, string>;

  const defaultPaginationModel: {
    page: number;
    limit: number;
    classAction?: string;
  } = {
    page: 1,
    limit: 200,
    classAction: '1',
  };

  const searchParametersWithoutPagination = omit(searchParameters, [
    'page',
    'limit',
  ]);

  const statisticsSearchParameters = pick(
    searchParametersWithoutPagination,
    Object.keys(searchFormPropertySchemas),
  );

  const [getStatistics, { isFetching, data: statistics }] =
    useLazyGetStatisticsQuery();
  const [getIncludeStatistics, { data: includeStatistics }] =
    useLazyGetStatisticsQuery();

  const { showSearchNotificationMessage } = useSearchNotification();

  const handleLoadStatistics = async () => {
    const ageCategoryQuery = buildAgeCategoryQuery(searchParameters);
    try {
      const updatedSearchParameters = {
        ...statisticsSearchParameters,
        ...ageCategoryQuery,
      };

      await getIncludeStatistics({}, false).unwrap();
      await getStatistics(updatedSearchParameters, false).unwrap();
    } catch (error) {
      if (isServerError(error)) {
        showSearchNotificationMessage(error);
      }
    }
  };

  useEffect(() => {
    const isPaginationParametersExist =
      Number(searchParameters.limit) && Number(searchParameters.page);
    const maxLimit = Math.max(...PAGE_SIZE_OPTIONS);
    const isLimitOvercharged = Number(searchParameters.limit) > maxLimit;

    if (isPaginationParametersExist && !isLimitOvercharged) {
      return;
    }
    const getLocalStorageClassAction = localStorage.getItem(
      EIncludeExcludeKeys.CLASS_ACTION_NAME,
    );
    if (getLocalStorageClassAction === EIncludeExcludeValue.NO) {
      delete defaultPaginationModel.classAction;
    }
    if (location.key === COMMON.DEFAULT) {
      defaultPaginationModel.classAction = EIncludeExcludeValue.YES;
    }
    const parametersWithPagination = {
      ...defaultPaginationModel,
      ...searchParameters,
    };
    navigate(AdminPanelRoutesPaths.CASES_LIST, parametersWithPagination);
  }, [location.search]);

  useEffect(() => {
    const parametersWithPagination = {
      ...defaultPaginationModel,
      ...searchParameters,
    };
    setTimeout(() => {
      navigate(AdminPanelRoutesPaths.CASES_LIST, parametersWithPagination);
    }, 1000);
  }, []);

  useEffect(() => {
    handleLoadStatistics();
  }, [location.search]);

  const profileExists = 'profile' in currentUser;
  const statisticsData = selectStatistic(statistics);
  const includeStatisticsData = selectStatistic(includeStatistics);

  return (
    <Search
      profileExists={profileExists}
      statisticsData={statisticsData}
      isFetching={isFetching}
      includeStatisticsData={includeStatisticsData}
    />
  );
};
