import { Chip, TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';

import { ISavedSearchData } from '@bvi/api-interfaces/response/case';

import { SavedSearchEntryRow } from '../saved-search-entry-row';

import { styles } from './styles';

export interface ISavedSearchRowProperties {
  savedSearch: ISavedSearchData;
}

export const SavedSearchRow: FC<ISavedSearchRowProperties> = ({
  savedSearch,
}) => {
  return (
    <>
      <TableRow sx={styles.savedSearchRow}>
        <TableCell>
          <Typography variant="caption">{savedSearch.name}</Typography>
        </TableCell>
        <TableCell />
        <TableCell>
          {savedSearch.isUpdatesAvailable && (
            <Chip
              label="Update Available"
              sx={styles.updateAvailableChip}
              color="success"
              size="small"
            />
          )}
        </TableCell>
      </TableRow>
      {savedSearch.entries.map((entry) => (
        <SavedSearchEntryRow
          key={`saved-search-entry-${entry.id}`}
          entry={entry}
        />
      ))}
    </>
  );
};
