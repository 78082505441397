import { z } from 'zod';

import { CaseEvents } from '@bvi/api-interfaces/entity/case';

export const buildValidationSchema = () => {
  return z.object({
    event: z.enum(
      Object.values(CaseEvents) as [CaseEvents, ...Array<CaseEvents>],
    ),
    declineReason: z.string().min(1).optional(),
  });
};
