import { Stack } from '@mui/material';

import { CasesCreateForm } from '@bvi/admin-panel/feature/cases-form/ui/create-form';

export const CasesCreatePage = () => {
  return (
    <Stack direction="column">
      <CasesCreateForm />
    </Stack>
  );
};
