import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateUserMutation } from '@bvi/admin-panel/entities/users/api-slice';
import { ChangeUserCompanyForm } from '@bvi/admin-panel/feature/clients/ui/change-company-form';
import { BlockUserMenuItem } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu/block-user-menu-item';
import { ConfirmUserMenuItem } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu/confirm-user-menu-item';
import { RestoreUserMenuItem } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu/restore-user-menu-item';
import { IManageUserMenuProperties } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu/types';
import { UserStatus } from '@bvi/api-interfaces/entity/user';
import { PaperModal } from '@bvi/common-components';

export const ManageUserMenu: FC<IManageUserMenuProperties> = ({
  user,
  company,
}) => {
  const { t } = useTranslation();

  const [updateUser] = useUpdateUserMutation();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const handleClickOnMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElement(null);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSetStatus = (status: UserStatus) => {
    return async () => {
      await updateUser([user.id, { status }]);
      handleCloseMenu();
    };
  };

  useEffect(() => {
    if (!isModalOpen) {
      handleCloseMenu();
    }
  }, [isModalOpen]);

  return (
    <>
      <IconButton onClick={handleClickOnMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleOpenModal}>
          {t('users.actions.move.caption')}
        </MenuItem>
        <ConfirmUserMenuItem
          userStatus={user.status}
          onClick={handleSetStatus(UserStatus.CONFIRMED)}
        />
        <BlockUserMenuItem
          userStatus={user.status}
          onClick={handleSetStatus(UserStatus.DELETED)}
        />
        <RestoreUserMenuItem
          userStatus={user.status}
          onClick={handleSetStatus(UserStatus.CONFIRMED)}
        />
      </Menu>
      <PaperModal open={isModalOpen} onClose={handleCloseModal}>
        <ChangeUserCompanyForm
          user={user}
          companyId={company.id}
          onSuccess={handleCloseModal}
        />
      </PaperModal>
    </>
  );
};
