import { Routes, Route, Navigate } from 'react-router-dom';

import { LoginPage } from '@bvi/admin-panel/pages';
import { AuthRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { AuthLayout } from '@bvi/admin-panel/widgets/layouts';

export const AuthRoutes = () => {
  return (
    <AuthLayout>
      <Routes>
        <Route path={AuthRoutesPaths.LOGIN} element={<LoginPage />} />
        <Route path="*" element={<Navigate to={AuthRoutesPaths.LOGIN} />} />
      </Routes>
    </AuthLayout>
  );
};
