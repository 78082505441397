import { theme } from '@bvi/mui-theme';

export const styles = {
  head: {
    color: theme.palette.grey[300],
  },
  body: {
    fontWeight: 'bold',
  },
};
