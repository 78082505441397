import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ISubscriptionData } from '@bvi/api-interfaces/response/subscription';
import { PaperModal } from '@bvi/common-components';

import { ChangeSubscriptionForm } from './change-subscription-form';

export interface IClientSubscriptionTabProperties {
  subscription: ISubscriptionData;
}

export const ClientSubscriptionTab: FC<IClientSubscriptionTabProperties> = ({
  subscription,
}) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        {t('subscriptions.buttons.manage.caption')}
      </Button>
      <PaperModal open={isOpen} onClose={handleClose}>
        <ChangeSubscriptionForm
          subscription={subscription}
          onSuccess={handleClose}
        />
      </PaperModal>
    </>
  );
};
