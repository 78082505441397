import { routes } from '@bvi/admin-panel/api-routes';
import { apiContainer, TagTypes } from '@bvi/admin-panel/shared/api';
import { ISignInRequest } from '@bvi/api-interfaces/request/auth';
import { IAdminResponse } from '@bvi/api-interfaces/response/admin';
import { BaseResponse } from '@bvi/api-interfaces/response/base';

export const authApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IAdminResponse, ISignInRequest>({
      query: (data) => ({
        url: routes.adminsAuth.signIn(),
        method: 'POST',
        data,
      }),
      invalidatesTags: (_result, error) =>
        error ? [] : [TagTypes.CURRENT_USER],
    }),
    me: builder.query<BaseResponse<IAdminResponse>, void>({
      query: () => ({
        url: routes.admins.me(),
        method: 'GET',
      }),
      providesTags: [TagTypes.CURRENT_USER],
    }),
    signOut: builder.mutation<unknown, void>({
      query: () => ({
        url: routes.adminsAuth.signOut(),
        method: 'POST',
      }),
      invalidatesTags: (_result, error) =>
        error ? [] : [TagTypes.CURRENT_USER],
    }),
  }),
});

export const { useMeQuery, useLoginMutation, useSignOutMutation } = authApi;
