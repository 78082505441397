import { Grid, Stack, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { useTranslation } from 'react-i18next';

import { useUpdateCaseMutation } from '@bvi/admin-panel/entities/cases/api-slice';
import { useLazyGetSettingsQuery } from '@bvi/admin-panel/entities/search/api-slice';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import {
  CaseInformation,
  PropertyKey,
} from '@bvi/api-interfaces/entity/case-property';
import { IUpdateCaseRequest } from '@bvi/api-interfaces/request/case';
import { ICaseData } from '@bvi/api-interfaces/response/case';
import { isServerError } from '@bvi/axios-query';
import {
  CasesForm,
  IOnSettingsLoadQuery,
  OnSettingsLoad,
} from '@bvi/cases-form';
import { BackButton, Breadcrumbs } from '@bvi/common-components';
import { formatDate } from '@bvi/date-utils';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { useNavigateAfterSubmit } from '../../lib/navigate-after-submit';

type CasesEditFormProperties = {
  case: ICaseData;
};

export const CasesEditForm: React.FC<CasesEditFormProperties> = (
  properties,
) => {
  const { case: _case } = properties;
  const navigate = useNavigateAfterSubmit();
  const { showNotificationMessage } = useNotistack();
  const [updateCaseRequest] = useUpdateCaseMutation();
  const [getSettings] = useLazyGetSettingsQuery();
  const { t } = useTranslation('');

  const onSubmitRequest = async (
    data: IUpdateCaseRequest,
    shouldSearch?: boolean,
  ) => {
    try {
      await updateCaseRequest([_case.id, data]).unwrap();

      navigate(_case.id, data, shouldSearch);
    } catch (error) {
      if (isServerError(error)) {
        showNotificationMessage(
          { type: error.type },
          NotistackMessageVariants.ERROR,
        );
      }
    }
  };

  const onLoadMoreSettings = async (
    entity: PropertyKey,
    query: IOnSettingsLoadQuery,
  ) => {
    const result = await getSettings({
      includes: [entity],
      pagination: {
        [entity]: {
          page: query.page,
          limit: 100,
          countryIds: query.country?.id,
          regionIds: query.region?.id,
          ids: query.ids?.join(','),
          ...(query.search && {
            names: query.search,
          }),
        },
      },
    });

    const data = result.data?.success ? result.data.payload.data : {};

    return {
      data: {
        payload: {
          data: data[entity]?.items ?? [],
          meta: data[entity]?.meta,
        },
      },
    };
  };

  const createdDate = formatDate(_case.createdAt);
  const title =
    isNull(_case.name) || isEmpty(_case.name) ? createdDate : _case.name;

  const breadcrumbs = [
    {
      label: t('cases.form.breadcrumbs.cases'),
      link: AdminPanelRoutesPaths.PENDING_CASES_LIST,
    },
    {
      label: title,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs items={breadcrumbs} />
      </Grid>
      <Grid item xs={12} mb={3}>
        <Stack direction="row" alignItems="center">
          <BackButton />
          <Typography variant="h1" ml={1}>
            {title}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <CasesForm
          onLoadMoreSettings={onLoadMoreSettings as OnSettingsLoad}
          onSubmitRequest={onSubmitRequest}
          case={_case}
          hiddenFields={[
            CaseInformation.CLAIMANT_NAME,
            'fileName',
            'perpetratorName',
          ]}
        />
      </Grid>
    </Grid>
  );
};
