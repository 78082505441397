import { Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetCompanyCasesQuery } from '@bvi/admin-panel/entities/clients/api-slice';
import { selectCompanyCases } from '@bvi/admin-panel/entities/clients/selectors';
import { styles } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-cases-tab/styles';
import { Span } from '@bvi/common-components';

import { ClientCasesTable } from './client-cases-table';

export interface IClientCasesTabProperties {
  companyId: number;
}

export const ClientCasesTab: FC<IClientCasesTabProperties> = ({
  companyId,
}) => {
  const { t } = useTranslation();

  const userCases = useSelector(selectCompanyCases(companyId));
  const { isFetching } = useGetCompanyCasesQuery(companyId);

  return (
    <>
      <Typography fontWeight="bold" pb={3}>
        <Span>{t('clients.details.tabs.cases.title')}</Span>
        <Span sx={styles.clientCasesCount}>{userCases.length}</Span>
      </Typography>
      <ClientCasesTable userCases={userCases} isLoading={isFetching} />
    </>
  );
};
