import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateClientMutation } from '@bvi/admin-panel/entities/clients/api-slice';
import {
  IClientDetailsHeaderProperties,
  IClientStateButtonConfig,
} from '@bvi/admin-panel/feature/clients/ui/client-details-header/types';
import { EditCompanyForm } from '@bvi/admin-panel/feature/clients/ui/edit-company-form';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { ClientStatus } from '@bvi/api-interfaces/entity/client';
import { ConfirmActionButton, PaperModal } from '@bvi/common-components';

const CLIENT_STATE_BUTTONS: Record<ClientStatus, IClientStateButtonConfig> = {
  [ClientStatus.Unconfirmed]: {
    nextState: ClientStatus.Active,
    caption: 'clients.details.buttons.confirm.caption',
    question: 'clients.details.buttons.confirm.question',
    props: undefined,
  },
  [ClientStatus.Active]: {
    nextState: ClientStatus.Archived,
    caption: 'clients.details.buttons.block.caption',
    question: 'clients.details.buttons.block.question',
    props: {
      color: 'error',
    },
  },
  [ClientStatus.Archived]: {
    nextState: ClientStatus.Active,
    caption: 'clients.details.buttons.restore.caption',
    question: 'clients.details.buttons.restore.question',
    props: undefined,
  },
};

export const ClientDetailsHeader: FC<IClientDetailsHeaderProperties> = ({
  client,
}) => {
  const { t } = useTranslation();

  const [updateClient] = useUpdateClientMutation();

  const [isOpen, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSetState = useCallback(
    (status: ClientStatus) => async () => {
      if (client) {
        await updateClient([client.id, { status }]);
      }
    },
    [updateClient, client],
  );

  return (
    <Grid container my={4} alignItems="center">
      <Grid item xs={12}>
        <Breadcrumbs separator="|">
          <Link underline="hover" href={AdminPanelRoutesPaths.CLIENTS_LIST}>
            {t('clients.details.breadcrumbs.clients')}
          </Link>
          {client ? (
            <Typography>{client?.company.name}</Typography>
          ) : (
            <Skeleton width="10em" />
          )}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={9}>
        <Stack direction="row" alignItems="center" mt={2}>
          {client ? (
            <Typography variant="h1">{client?.company.name}</Typography>
          ) : (
            <Skeleton width="10em" sx={{ fontSize: '1.3rem' }} />
          )}
        </Stack>
      </Grid>
      <Grid item xs={3} justifyContent="flex-end" display="flex">
        <Button variant="outlined" disabled={!client} onClick={handleOpen}>
          {t('clients.details.buttons.editCompany.caption')}
        </Button>
        {client && (
          <>
            <PaperModal open={isOpen} onClose={handleClose}>
              <EditCompanyForm
                company={client.company}
                onSuccess={handleClose}
              />
            </PaperModal>
            <ConfirmActionButton
              variant="outlined"
              sx={{ ml: 3 }}
              question={t(CLIENT_STATE_BUTTONS[client.status].question)}
              confirm={t('dialog.confirm')}
              cancel={t('dialog.cancel')}
              onClick={handleSetState(
                CLIENT_STATE_BUTTONS[client.status].nextState,
              )}
              {...CLIENT_STATE_BUTTONS[client.status].props}
            >
              {t(CLIENT_STATE_BUTTONS[client.status].caption)}
            </ConfirmActionButton>
          </>
        )}
      </Grid>
    </Grid>
  );
};
