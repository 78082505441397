import { createSelector } from '@reduxjs/toolkit';

import { IAdminResponse } from '@bvi/api-interfaces/response/admin';

import { authApi } from './api-slice';

export const selectCurrentUserResult = (query: void) =>
  authApi.endpoints.me.select(query);

export const selectCurrentUser = (query: void) =>
  createSelector(selectCurrentUserResult(query), ({ data }) => {
    if (!data?.success) {
      return {} as IAdminResponse;
    }

    return data.payload.data;
  });
