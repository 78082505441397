import { Box } from '@mui/material';
import { FC } from 'react';

import { IPhoneNumberProperties } from './types';

export const PhoneNumber: FC<IPhoneNumberProperties> = ({
  value,
  ...properties
}) => {
  return <Box {...properties}>{formatPhoneNumber(value)}</Box>;
};

const PHONE_NUMBER_REGEX = /(\d).*(\d{3}).*(\d{3}).*(\d{2}).*(\d{2})/;

const formatPhoneNumber = (phoneNumber: string) => {
  const parts = PHONE_NUMBER_REGEX.exec(phoneNumber);

  if (!parts || parts.length < 6) {
    return phoneNumber;
  }

  return '+' + parts.slice(1).join(' ');
};
