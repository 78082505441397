import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ManageCaseMenu } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-cases-tab/client-cases-table/manage-case-menu';
import { CaseStatus } from '@bvi/api-interfaces/entity/case';
import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import {
  LoadingTableBody,
  OverflowingText,
  info,
  Image,
} from '@bvi/common-components';

import { styles } from './styles';

export interface IUserCasesTableProperties {
  userCases: Array<IUserCaseWithCaseData>;
  isLoading?: boolean;
}

export const ClientCasesTable: FC<IUserCasesTableProperties> = ({
  userCases,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="60%">
              {t('clients.details.tabs.cases.table.columns.description')}
            </TableCell>
            <TableCell width="20%">
              {t('clients.details.tabs.cases.table.columns.status')}
            </TableCell>
            <TableCell width="5%" />
          </TableRow>
        </TableHead>
        <LoadingTableBody rows={2} cols={2} isLoading={isLoading}>
          {userCases.map((userCase) => {
            const showInfoIcon =
              userCase.case.status === CaseStatus.DENIED &&
              userCase.declineReason;
            const status = t(`cases.common.status.${userCase.case.status}`);

            return (
              <TableRow key={userCase.id}>
                <TableCell sx={styles.description}>
                  <OverflowingText
                    text={userCase.case.description ?? ''}
                    sx={styles.statusText}
                  />
                </TableCell>
                <TableCell>
                  <OverflowingText text={status} sx={styles.statusText} />
                  {showInfoIcon && (
                    <Tooltip title={userCase.declineReason}>
                      <Image
                        src={info}
                        alt="info"
                        sx={styles.declineReasonIcon}
                      />
                    </Tooltip>
                  )}
                </TableCell>

                <TableCell>
                  <ManageCaseMenu case={userCase.case} />
                </TableCell>
              </TableRow>
            );
          })}
        </LoadingTableBody>
      </Table>
    </TableContainer>
  );
};
