import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useCreateClientMutation,
  useGetCompanySizesQuery,
  useGetSubscriptionPlansQuery,
} from '@bvi/admin-panel/entities/clients/api-slice';
import {
  selectCompanySizes,
  selectSubscriptionPlans,
} from '@bvi/admin-panel/entities/clients/selectors';
import { IAddClientFormProperties } from '@bvi/admin-panel/feature/clients/ui/add-client-form/types';
import { ICreateClientRequest } from '@bvi/api-interfaces/request/client';
import { isServerError } from '@bvi/axios-query';
import { Form, FormDatePicker } from '@bvi/common-components';
import { NotistackMessageVariants, useNotistack } from '@bvi/notistack';

import { buildValidationSchema } from './schema';

export const AddClientForm: FC<IAddClientFormProperties> = ({ onSuccess }) => {
  const { data: companySizes, isLoading: isCompanySizesLoading } =
    useGetCompanySizesQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        data: selectCompanySizes(data),
        isLoading,
      }),
    });

  const { data: subscriptionPlans, isLoading: isSubscriptionPlansLoading } =
    useGetSubscriptionPlansQuery(undefined, {
      selectFromResult: ({ data, isLoading }) => ({
        data: selectSubscriptionPlans(data),
        isLoading,
      }),
    });

  const [createClientRequest, { isLoading, error }] = useCreateClientMutation();
  const { t } = useTranslation();
  const { showNotificationMessage } = useNotistack();

  const validationSchema = buildValidationSchema(t);
  const methods = useForm<ICreateClientRequest>({
    resolver: zodResolver(validationSchema),
    defaultValues: { subscriptionExpiresAt: undefined },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: ICreateClientRequest) => {
    await createClientRequest(data);
    onSuccess?.();
  };

  useEffect(() => {
    if (isServerError(error)) {
      showNotificationMessage(
        { type: error.type },
        NotistackMessageVariants.ERROR,
      );
    }
  }, [error, showNotificationMessage]);

  return (
    <FormProvider {...methods}>
      <Typography variant="h1" mb={2}>
        {t('clients.add.title')}
      </Typography>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t('clients.add.subtitleCompany')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('name')}
              variant="outlined"
              fullWidth
              label={t('clients.add.form.name.label')}
              placeholder={t('clients.add.form.name.placeholder')}
              error={Boolean(errors.name)}
              helperText={<>{errors.name?.message}</>}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel
                id="select-company-size"
                error={Boolean(errors.companySizeId)}
              >
                {t('clients.add.form.companySize.label')}
              </InputLabel>
              <Select
                {...register('companySizeId', {
                  valueAsNumber: true,
                  required: true,
                })}
                label={t('clients.add.form.companySize.label')}
                labelId="select-company-size"
                placeholder={t('clients.add.form.companySize.placeholder')}
                error={Boolean(errors.companySizeId)}
                defaultValue={companySizes[0]?.id}
                disabled={isCompanySizesLoading}
              >
                {companySizes.map((companySize) => (
                  <MenuItem key={companySize.id} value={companySize.id}>
                    {companySize.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.companySizeId && (
                <FormHelperText error>
                  {errors.companySizeId?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('contactPerson')}
              variant="outlined"
              fullWidth
              label={t('clients.add.form.contactPerson.label')}
              placeholder={t('clients.add.form.contactPerson.placeholder')}
              error={Boolean(errors.contactPerson)}
              helperText={<>{errors.contactPerson?.message}</>}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              {...register('contactEmail')}
              variant="outlined"
              fullWidth
              label={t('clients.add.form.contactEmail.label')}
              placeholder={t('clients.add.form.contactEmail.placeholder')}
              error={Boolean(errors.contactEmail)}
              helperText={<>{errors.contactEmail?.message}</>}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t('clients.add.subtitleSubscription')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              field="subscriptionExpiresAt"
              label={t('clients.add.form.subscriptionExpiresAt.label')}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel
                id="select-subscription-plan"
                error={Boolean(errors.subscriptionPlanId)}
              >
                {t('clients.add.form.subscriptionPlan.label')}
              </InputLabel>
              <Select
                {...register('subscriptionPlanId', {
                  valueAsNumber: true,
                  required: true,
                })}
                label={t('clients.add.form.subscriptionPlan.label')}
                labelId="select-subscription-plan"
                placeholder={t('clients.add.form.subscriptionPlan.placeholder')}
                error={Boolean(errors.subscriptionPlanId)}
                defaultValue={subscriptionPlans[0]?.id}
                disabled={isSubscriptionPlansLoading}
              >
                {subscriptionPlans.map((subscriptionPlan) => (
                  <MenuItem
                    key={subscriptionPlan.id}
                    value={subscriptionPlan.id}
                  >
                    {subscriptionPlan.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.subscriptionPlanId && (
                <FormHelperText error>
                  {errors.subscriptionPlanId?.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
            >
              {t('clients.add.form.submit')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};
