import { FormControl } from '@mui/material';
import { DataGridProProps } from '@mui/x-data-grid-pro';
import { FC } from 'react';

import { StyledDataGrid } from '../';

export const FormDataGrid: FC<DataGridProProps> = (properties) => {
  return (
    <FormControl fullWidth>
      <StyledDataGrid {...properties} paginationMode="server" />
    </FormControl>
  );
};
