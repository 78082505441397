import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTransitUserCaseMutation } from '@bvi/admin-panel/entities/cases/api-slice';
import { buildValidationSchema } from '@bvi/admin-panel/feature/pending-cases/lib/schema';
import { CaseEvents } from '@bvi/api-interfaces/entity/case';
import { ITransitUserCaseRequest } from '@bvi/api-interfaces/request/case';
import { IUserCaseData } from '@bvi/api-interfaces/response/case';
import { Form } from '@bvi/common-components';

export interface IDeclineUserCaseFormParameters {
  userCase: IUserCaseData;
  onSuccess?: () => void;
  onCancel?: () => void;
}

export const DeclineUserCaseForm: FC<IDeclineUserCaseFormParameters> = ({
  userCase,
  onSuccess,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [transitUserCase, { isLoading }] = useTransitUserCaseMutation();

  const validationSchema = buildValidationSchema();
  const methods = useForm<ITransitUserCaseRequest>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      event: CaseEvents.Deny,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: ITransitUserCaseRequest) => {
    await transitUserCase([userCase.id, data]);
    onSuccess?.();
  };

  return (
    <FormProvider {...methods}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1" color="primary" pb={2}>
              {t('pendingCases.declineForm.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('declineReason')}
              fullWidth
              label={t('pendingCases.declineForm.fields.declineReason.label')}
              error={Boolean(errors.declineReason)}
              helperText={<>{errors.declineReason?.message}</>}
            />
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isLoading}
              data-testid={`submit-deny-case-${userCase.id}`}
            >
              {t('pendingCases.declineForm.buttons.decline.caption')}
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" fullWidth onClick={onCancel}>
              {t('pendingCases.declineForm.buttons.close.caption')}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormProvider>
  );
};
