export enum AuthRoutesPaths {
  LOGIN = '/',
}

export enum AdminPanelRoutesPaths {
  CASES_LIST = '/cases',
  CASES_CREATE = '/cases/create',
  CASES_EDIT = '/cases/:id/edit',
  CASES_DETAILS = '/cases/:id',

  SEARCH = '/search',
  PENDING_CASES_LIST = '/pending-cases',

  CLIENTS_LIST = '/clients',
  CLIENT_DETAILS = '/clients/:id',

  PROFILE = '/profile',
}
