import { Paper, Skeleton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IClientDetailsTableProperties } from '@bvi/admin-panel/feature/clients/ui/client-details-table/types';
import { formatDate } from '@bvi/date-utils';

import { styles } from './styles';

export const ClientDetailsTable: FC<IClientDetailsTableProperties> = ({
  client,
}) => {
  const { t } = useTranslation();
  const subscriptionExpiresAt = client?.subscription.expiresAt
    ? formatDate(client.subscription.expiresAt)
    : t('clients.details.table.columns.neverExpires');

  return (
    <Grid container component={Paper} rowSpacing={1} p={2}>
      <Grid xs={2} style={styles.head}>
        {t('clients.details.table.columns.companySize')}
      </Grid>
      <Grid xs={3} style={styles.head}>
        {t('clients.details.table.columns.subscriptionPlan')}
      </Grid>
      <Grid xs={3} style={styles.head}>
        {t('clients.details.table.columns.subscriptionExpiresAt')}
      </Grid>
      <Grid xs={2} style={styles.head}>
        {t('clients.details.table.columns.status')}
      </Grid>
      <Grid xs={2} style={styles.head}>
        {t('clients.details.table.columns.numberOfUsers')}
      </Grid>
      <Grid xs={2} style={styles.body}>
        {client ? client.company.size.name : <Skeleton width="80%" />}
      </Grid>
      <Grid xs={3} style={styles.body}>
        {client ? client.subscription.plan.name : <Skeleton width="80%" />}
      </Grid>
      <Grid xs={3} style={styles.body}>
        {client ? subscriptionExpiresAt : <Skeleton width="80%" />}
      </Grid>
      <Grid xs={2} style={styles.body}>
        {client ? client.status : <Skeleton width="80%" />}
      </Grid>
      <Grid xs={2} style={styles.body}>
        {client ? client.company.users.length : <Skeleton width="80%" />}
      </Grid>
    </Grid>
  );
};
