import { routes } from '@bvi/admin-panel/api-routes';
import { parametersBuilder } from '@bvi/admin-panel/entities/search/parametersBuilder';
import {
  TagTypes,
  apiContainer,
  provideTagsList,
} from '@bvi/admin-panel/shared/api';
import { BaseResponse } from '@bvi/api-interfaces/response/base';
import {
  ICaseStatisticsData,
  IFullCaseListResponse,
} from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { IGetSettingsQuery } from '@bvi/cases-search';

export const defaultGetCasesParameters = {
  limit: 50,
};

export const searchApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<BaseResponse<ISettingsData>, IGetSettingsQuery>({
      query: ({ includes, pagination }) => ({
        url: routes.adminCases.getSettings(),
        method: 'GET',
        params: parametersBuilder({ includes, pagination }),
      }),
      providesTags(_result, error) {
        return error ? [] : [TagTypes.CASE_SETTINGS];
      },
    }),
    getStatistics: builder.query<BaseResponse<ICaseStatisticsData>, unknown>({
      query: (parameters) => ({
        url: routes.adminCases.getStatistics(),
        method: 'GET',
        params: parameters,
      }),
    }),
    getCases: builder.query<IFullCaseListResponse, unknown>({
      query: (parameters) => ({
        url: routes.adminCases.search(),
        method: 'GET',
        params: parameters,
      }),
      providesTags: provideTagsList(TagTypes.CASES_LIST),
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  useGetStatisticsQuery,
  useLazyGetStatisticsQuery,
  useGetCasesQuery,
  useLazyGetCasesQuery,
} = searchApi;
