import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-users-tab/client-users-table/styles';
import { IClientUsersTableProperties } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-users-tab/client-users-table/types';
import { UserStatus } from '@bvi/api-interfaces/entity/user';

import { ClientUsersTableRow } from './client-users-table-row';

const ORDERED_STATUSES = [
  UserStatus.WAITING_CONFIRMATION,
  UserStatus.CONFIRMED,
  UserStatus.DELETED,
];

export const ClientUsersTable: FC<IClientUsersTableProperties> = ({
  company,
}) => {
  const { t } = useTranslation();

  const sortedUsers = [...company.users].sort((a, b) => {
    if (a.status === b.status) {
      return a.createdAt - b.createdAt;
    }
    return (
      ORDERED_STATUSES.indexOf(a.status) - ORDERED_STATUSES.indexOf(b.status)
    );
  });

  const tableColumns = [
    {
      title: t('users.table.columns.name'),
    },
    {
      title: t('users.table.columns.email'),
      width: '28%',
    },
    {
      title: t('users.table.columns.phone'),
      width: '17%',
    },
    {
      title: t('users.table.columns.status'),
      width: '20%',
    },
    {
      width: '70px',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={styles.fixedTable}>
        <TableHead>
          <TableRow>
            {tableColumns.map((column, index) => (
              <TableCell width={column.width} key={index}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedUsers.map((user) => (
            <ClientUsersTableRow user={user} company={company} key={user.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
