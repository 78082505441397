import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { useLazyGetSettingsQuery } from '@bvi/admin-panel/entities/search/api-slice';
import { isEqualParameters } from '@bvi/admin-panel/feature/search/lib/isEqualParameters';
import { CaseTable } from '@bvi/admin-panel/feature/search/ui/case-table';
import { SearchResult } from '@bvi/admin-panel/feature/search/ui/search-result';
import { AdminPanelRoutesPaths } from '@bvi/admin-panel/shared/constants/routes';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import {
  SearchLayout,
  SearchFilters,
  IGetSettingsQuery,
  SearchContext,
  mapFormDataToSubmit,
  ISearchFormDataSchema,
  SEARCH_PARAMS_KEY,
} from '@bvi/cases-search';
import { useNavigation } from '@bvi/navigation-utils';

const Filters = ({
  profileExists,
  statisticsData,
  isFetching,
}: {
  profileExists: boolean;
  statisticsData: ICaseStatisticsData;
  isFetching: boolean;
}) => {
  const [getSettings] = useLazyGetSettingsQuery();
  const handleLoadSettings = async (data: IGetSettingsQuery) => {
    const result = await getSettings(data);
    return result.data?.success ? result.data.payload.data : {};
  };

  return (
    <SearchFilters
      onLoadSettings={handleLoadSettings}
      profileExists={profileExists}
      statisticsData={statisticsData}
      isFetching={isFetching}
    />
  );
};

export const Search: React.FC<{
  profileExists: boolean;
  statisticsData: ICaseStatisticsData;
  isFetching: boolean;
  includeStatisticsData?: ICaseStatisticsData;
}> = ({ profileExists, statisticsData, includeStatisticsData, isFetching }) => {
  const navigate = useNavigation();
  const [, handleSaveSearchParameters] = useLocalStorage(
    SEARCH_PARAMS_KEY,
    '{}',
  );
  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;

  const handleSubmit = (data: ISearchFormDataSchema) => {
    const preparedData = mapFormDataToSubmit(data);

    const isTheSameParameters = isEqualParameters(data, searchParameters);

    if (isTheSameParameters) {
      return;
    }

    handleSaveSearchParameters(JSON.stringify(preparedData));

    navigate(AdminPanelRoutesPaths.CASES_LIST, preparedData);
  };

  return (
    <SearchContext.Provider
      value={{
        onSubmit: handleSubmit,
        route: AdminPanelRoutesPaths.CASES_LIST,
        includeStatisticsData: includeStatisticsData,
        allowToSearchConfidential: true,
      }}
    >
      <SearchLayout
        filters={
          <Filters
            profileExists={profileExists}
            statisticsData={statisticsData}
            isFetching={isFetching}
          />
        }
        results={<SearchResult />}
        casesTable={<CaseTable />}
      />
    </SearchContext.Provider>
  );
};
