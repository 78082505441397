import { useParams } from 'react-router-dom';

import { useGetClientsQuery } from '@bvi/admin-panel/entities/clients/api-slice';
import { selectClient } from '@bvi/admin-panel/entities/clients/selectors';
import { ClientDetailsHeader } from '@bvi/admin-panel/feature/clients/ui/client-details-header';
import { ClientDetailsTable } from '@bvi/admin-panel/feature/clients/ui/client-details-table';
import { ClientTabBar } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar';

export const ClientDetailsPage = () => {
  const { id } = useParams();
  const { data: client } = useGetClientsQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      data: selectClient(data, Number(id)),
      isLoading,
    }),
  });

  return (
    <>
      <ClientDetailsHeader client={client} />
      <ClientDetailsTable client={client} />
      {client && <ClientTabBar client={client} sx={{ my: 3 }} />}
    </>
  );
};
