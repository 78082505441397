import { theme } from '@bvi/mui-theme';

export const styles = {
  companyName: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  notAvailableColumn: {
    color: theme.palette.grey['200'],
  },
};
