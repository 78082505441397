import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ICaseListData } from '@bvi/api-interfaces/response/case';
import { PaginationTable, LoadingTableBody } from '@bvi/common-components';
import { SxProperties } from '@bvi/mui-theme';

import { PendingCasesTableRow } from './pending-cases-table-row';

export interface IPendingCasesTableProperties {
  sx?: SxProperties;
  page: number;
  count: number;
  cases: Array<ICaseListData>;
  isLoading: boolean;
  onPageChange: (page: number) => void;
}

export const TABLE_COLUMNS = [
  {
    title: 'pendingCases.list.table.columns.number',
    width: '50px',
  },
  {
    title: 'pendingCases.list.table.columns.user',
    width: '30%',
  },
  {
    title: 'pendingCases.list.table.columns.createdAt',
    width: '120px',
  },
  {
    title: 'pendingCases.list.table.columns.description',
    width: 'auto',
  },
];

export const PendingCasesTable: FC<IPendingCasesTableProperties> = ({
  sx,
  page,
  count,
  cases,
  isLoading,
  onPageChange,
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={sx}>
      <PaginationTable count={count} page={page} onPageChange={onPageChange}>
        <TableHead>
          <TableRow>
            {TABLE_COLUMNS.map((column) => (
              <TableCell key={column.title} width={column.width}>
                {t(column.title)}
              </TableCell>
            ))}
            <TableCell />
            <TableCell sx={{ width: '7%' }} />
          </TableRow>
        </TableHead>
        <LoadingTableBody
          rows={10}
          cols={TABLE_COLUMNS.length + 1}
          isLoading={isLoading}
          rowSx={{ minHeight: '66px' }}
        >
          {cases.map((_case) => (
            <PendingCasesTableRow key={_case.id} case={_case} />
          ))}
        </LoadingTableBody>
      </PaginationTable>
    </TableContainer>
  );
};
