import { createSelector } from '@reduxjs/toolkit';

import {
  ICaseListSearchQuery,
  IGetCaseRequest,
} from '@bvi/api-interfaces/request/case';
import { ICaseData } from '@bvi/api-interfaces/response/case';

import { casesApi } from './api-slice';

export const selectCasesListResult = (query: ICaseListSearchQuery) =>
  casesApi.endpoints.getCasesList.select(query);

export const selectCasesListMeta = (query: ICaseListSearchQuery) =>
  createSelector(selectCasesListResult(query), ({ data }) => {
    if (!data?.success) {
      return {};
    }

    return data.payload.meta;
  });

export const selectCasesTotalCount = (query: ICaseListSearchQuery) =>
  createSelector(selectCasesListMeta(query), (meta) => {
    return meta.total;
  });

export const selectCasesList = (query: ICaseListSearchQuery) =>
  createSelector(selectCasesListResult(query), ({ data }) => {
    if (!data?.success) {
      return [];
    }

    return data.payload.data;
  });

export const selectCaseResult = ({ id }: IGetCaseRequest) =>
  casesApi.endpoints.getCase.select({ id });

export const selectCase = ({ id }: IGetCaseRequest) =>
  createSelector(selectCaseResult({ id }), ({ data }) => {
    if (!data?.success) {
      return {} as ICaseData;
    }

    return data.payload.data;
  });
