import { TFunction } from 'i18next';
import { z } from 'zod';

export const buildValidationSchema = (t: TFunction) => {
  const validationFields = {
    email: z
      .string()
      .min(1, t('error.form.required'))
      .email(t('error.form.email'))
      .default(''),
    password: z.string().min(1, t('error.form.required')).default(''),
  };

  return z.object(validationFields);
};
