import { LoginForm } from '@bvi/admin-panel/feature/auth/ui/login-form';
import { Div, Logo } from '@bvi/common-components';

import { styles } from './styles';

export const LoginPage = () => {
  return (
    <Div sx={styles.container}>
      <Logo />
      <LoginForm />
    </Div>
  );
};
