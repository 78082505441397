import { Stack, Paper } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack justifyContent="center" alignItems="center" minHeight="100vh">
      <Paper>{children}</Paper>
    </Stack>
  );
};
