const EM_DASH = '\u2014';

export const formatRange = <T>(
  from: T | undefined,
  to: T | undefined,
  formatValue: (value: T) => string = String,
): string | undefined => {
  if (from && to) {
    return from === to
      ? formatValue(from)
      : `${formatValue(from)} ${EM_DASH} ${formatValue(to)}`;
  }

  if (from) {
    return formatValue(from);
  }

  if (to) {
    return `N/A ${EM_DASH} ${formatValue(to)}`;
  }

  return undefined;
};

export const formatAmount = (
  value: number | undefined,
  currencySymbol: string | undefined,
): string | undefined => {
  if (value !== undefined && currencySymbol) {
    return (
      currencySymbol +
      value.toLocaleString('en-US', { maximumFractionDigits: 0 })
    );
  }

  return undefined;
};
