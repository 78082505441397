import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IManageUserMenuItemProperties } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu/types';
import { UserStatus } from '@bvi/api-interfaces/entity/user';
import { ConfirmActionMenuItem } from '@bvi/common-components';

export const ConfirmUserMenuItem: FC<IManageUserMenuItemProperties> = ({
  userStatus,
  onClick,
}) => {
  const { t } = useTranslation();

  return userStatus === UserStatus.WAITING_CONFIRMATION ? (
    <ConfirmActionMenuItem
      question={t('users.actions.confirm.question')}
      confirm={t('dialog.confirm')}
      cancel={t('dialog.cancel')}
      onClick={onClick}
    >
      {t('users.actions.confirm.caption')}
    </ConfirmActionMenuItem>
  ) : null;
};
