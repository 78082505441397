import { TableCell, TableRow, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IClientUsersTableRowProperties } from '@bvi/admin-panel/feature/clients/ui/client-tab-bar/client-users-tab/client-users-table/client-users-table-row/types';
import { ManageUserMenu } from '@bvi/admin-panel/feature/clients/ui/manage-user-menu';
import { UserStatus } from '@bvi/api-interfaces/entity/user';
import { PhoneNumber, Span } from '@bvi/common-components';
import { theme } from '@bvi/mui-theme';

import { styles } from './styles';

export const ClientUsersTableRow: FC<IClientUsersTableRowProperties> = ({
  user,
  company,
  ...properties
}) => {
  const { t } = useTranslation();

  const isBlocked = user.status === UserStatus.DELETED;
  const color = isBlocked ? theme.palette.grey[300] : undefined;

  const userName = `${user.profile.firstName} ${user.profile.lastName}`;

  return (
    <TableRow {...properties}>
      <TableCell
        sx={{
          color: isBlocked
            ? theme.palette.grey[300]
            : theme.palette.primary.main,
          fontWeight: 'bold',
        }}
      >
        <Span sx={styles.tooltipContent}>{userName}</Span>
      </TableCell>
      <TableCell sx={{ color }}>
        <Tooltip title={user.email}>
          <Span sx={styles.tooltipContent}>{user.email}</Span>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ color }}>
        <PhoneNumber value={user.profile.phone} />
      </TableCell>
      <TableCell sx={{ color }}>{t(`users.statuses.${user.status}`)}</TableCell>
      <TableCell>
        <ManageUserMenu user={user} company={company} />
      </TableCell>
    </TableRow>
  );
};
