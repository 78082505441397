import { CircularProgress, Stack } from '@mui/material';

import { useMeQuery } from '@bvi/admin-panel/entities/admin-auth/api-slice';
import { useMinTimeToShowLoader } from '@bvi/admin-panel/shared/hooks/useMinTimeToShowLoader';

import { AdminPanelRoutes } from './AdminPanelRoutes';
import { AuthRoutes } from './AuthRoutes';

export const AppRoutes = () => {
  const { isLoading, isError } = useMeQuery();
  const shouldShowLoader = useMinTimeToShowLoader(isLoading, 300);

  if (shouldShowLoader) {
    return (
      <Stack alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress />
      </Stack>
    );
  }

  if (isError) {
    return <AuthRoutes />;
  }

  return <AdminPanelRoutes />;
};
