import { createSelector } from '@reduxjs/toolkit';

import { clientsApi } from '@bvi/admin-panel/entities/clients/api-slice';
import { getResponseDataOrEmptyArray } from '@bvi/api-interfaces/response';
import { IUserCaseWithCaseData } from '@bvi/api-interfaces/response/case';
import { IClientWithCompanyListResponse } from '@bvi/api-interfaces/response/client';
import { ICompanySizeListResponse } from '@bvi/api-interfaces/response/company-size';
import { ISubscriptionPlanListResponse } from '@bvi/api-interfaces/response/subscription-plan';

export const selectClients = createSelector(
  (response?: IClientWithCompanyListResponse) => response,
  (response) => (response?.success ? response.payload.data : []),
);

export const selectClient = createSelector(
  selectClients,
  (clients: unknown, id?: number) => id,
  (clients, id) =>
    Number.isFinite(id)
      ? clients.find((client) => client.id === id)
      : undefined,
);

export const selectCompanySizes = createSelector(
  (response?: ICompanySizeListResponse) => response,
  (response) => getResponseDataOrEmptyArray(response),
);

export const selectSubscriptionPlans = createSelector(
  (response?: ISubscriptionPlanListResponse) => response,
  (response) => getResponseDataOrEmptyArray(response),
);

export const selectCompanyCasesResult = (companyId: number) =>
  clientsApi.endpoints.getCompanyCases.select(companyId);

export const selectCompanyCases = (companyId: number) =>
  createSelector(
    selectCompanyCasesResult(companyId),
    ({ data }): Array<IUserCaseWithCaseData> => {
      if (!data?.success) {
        return [];
      }

      return getResponseDataOrEmptyArray(data);
    },
  );
