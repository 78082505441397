import { compose } from 'lodash/fp';

import { withLocalization } from '@bvi/admin-panel/app/providers/with-localization';

import { withI18n } from './with-i18n';
import { withNotistack } from './with-notistack';
import { withRouter } from './with-router';
import { withSentry } from './with-sentry';
import { withStore } from './with-store';
import { withTheme } from './with-theme';

export const withProviders = compose(
  withStore,
  withI18n,
  withRouter,
  withTheme,
  withNotistack,
  withLocalization,
  withSentry,
);
